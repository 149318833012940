import axios from "axios";
import config from "../config";

/**
 * Checks the login status of the user.
 * @returns {Promise<{success: boolean, username?: string}>} The result of the session validation.
 */
export const checkLoginStatus = async () => {
  const session_id = localStorage.getItem("session_id");
  const username = localStorage.getItem("username");

  if (!session_id || !username) {
    return { success: false };
  }

  try {
    const response = await axios.post(
      `${config.apiBaseUrl}/check_session.php`,
      { username, session_id },
      { withCredentials: true }
    );

    console.log("Response from check_session:", response.data);
    return response.data;
  } catch (err) {
    console.error("Error while checking session:", err);
    return { success: false };
  }
};

/**
 * Logs the user out by clearing the session and localStorage.
 * @param {Function} navigate - The navigation function from react-router.
 */
export const handleLogout = async (navigate) => {
  const username = localStorage.getItem("username");
  const session_id = localStorage.getItem("session_id");

  if (!username || !session_id) {
    console.error("No username or session ID found. Cannot log out.");
    // navigate("/");
    return;
  }

  try {
    const response = await axios.post(
      `${config.apiBaseUrl}/logout.php`,
      { username, session_id },
      { withCredentials: true }
    );

    console.log("Logout response:", response.data);

    if (response.data.success) {
      // Clear localStorage and redirect to the login page
      localStorage.removeItem("username");
      localStorage.removeItem("session_id");
      navigate("/");
    } else {
      console.error("Logout failed:", response.data.message);
    }
  } catch (error) {
    console.error("Error during logout:", error);
  }
};


// use this code to use this in other pages 

// useEffect(() => {
  //   const checkStatus = async () => {
  //     const result = await checkLoginStatus();
  //     if (result.success) {
  //       setLoggedIn(true);
  //       setUsername(result.username);
  //     } else {
  //       navigate("/"); // Navigate to login without page reload
  //     }
  //   };

  //   checkStatus();
  // }, [navigate]);

  // if (!loggedIn) return null;