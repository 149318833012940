import LoginPage from "./components/LoginPage";
import Dashboard from "./components/Dashboard";
import Quotes from "./components/Quotes/Quotes";
import AddQuote from "./components/Quotes/AddQuote";
import EditQuote from "./components/Quotes/EditQuote";
import QuoteDetails from "./components/Quotes/DetailsPage";
import QuoteFilesPage from "./components/Quotes/FileUpload";
import Emp from "./components/Employee/Table";
import AddEmp from "./components/Employee/Add";
import EditEmp from "./components/Employee/Edit";
import EmpDetails from "./components/Employee/Details";
import EmpFilesPage from "./components/Employee/FileUpload";
import TransactionTable from "./components/Transactions/Table";
import AddTransactions from "./components/Transactions/Add";
import EditTransaction from "./components/Transactions/Edit";
import TransactionDetails from "./components/Transactions/Details";
import TransactionFilesPage from "./components/Transactions/FileUpload";
import SiteTable from "./components/Site/Table";
import AddSite from "./components/Site/Add";
import EditSite from "./components/Site/Edit";
import SiteDetails from "./components/Site/Details";
import SiteFilesPage from "./components/Site/FileUpload";
import AttendanceTable from "./components/Attendence/Table";
import AddAttendance from "./components/Attendence/Add";
import EditAttendance from "./components/Attendence/Edit";
import AttendanceDetails from "./components/Attendence/Details";
import AttendanceFilesPage from "./components/Attendence/FileUpload";

import Icons from "./utils/icon";

import HomeIcon from "@mui/icons-material/Home";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import BadgeIcon from "@mui/icons-material/Badge";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AttendanceIcon from "@mui/icons-material/AccessAlarm";  // Or another appropriate icon
import InvoiceTable from "./components/Invoice/Table";
import AddInvoice from "./components/Invoice/Add";
import EditInvoice from "./components/Invoice/Edit";
import InvoiceDetails from "./components/Invoice/Details";
import InvoiceFilesPage from "./components/Invoice/FileUpload";

import homeIcon from './icons/home-icon.png';
import quoteIcon from './icons/quotes-icon.png';
import addQuote from './icons/add-quotes-icon.png';
import transaction from './icons/add-transactions.png';
import addTransaction from './icons/transactions.png';

import employee from './icons/employee.webp';
import addEmployee from './icons/add-employee.png';
import site from './icons/site.png';
import addSite from './icons/add-site.svg';
import attendance from './icons/attendance.png';
import invoice from './icons/invoice.webp';



const routes = [
  {
    path: "/",
    element: <LoginPage />,
    title: "Login",
    mainPage: false,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    title: "Dashboard",
    mainPage: true,
    showInDashboard: true,
    icon: <HomeIcon />,
   image: homeIcon
  },
  {
    path: "/quotes",
    element: <Quotes />,
    title: "Quotes TAC",
    mainPage: true,
    showInDashboard: true,
    icon: <FormatQuoteIcon />,  
    image: quoteIcon
  },
  {
    path: "/add_quote",
    element: <AddQuote />,
    title: "Add Quote",
    mainPage: false,
    showInDashboard: true,
    icon: <AddIcon />,  
    image: addQuote
  },
  {
    path: "/edit_quote/:id",
    element: <EditQuote />,
    title: "Edit Quote",
    mainPage: false,
    icon: <EditIcon />,  // Using EditIcon directly
  },
  {
    path: "/quote_details/:id",
    element: <QuoteDetails />,
    title: "Quote Details",
    mainPage: false,
  },
  {
    path: "/quote_files/:id",
    element: <QuoteFilesPage />,
    title: "Quote Files",
    mainPage: false,
  },
  {
    path: "/emp",
    element: <Emp />,
    title: "Employee List",
    mainPage: true,
    showInDashboard: true,
    icon: <BadgeIcon />, 
    image: employee
  },
  {
    path: "/add_emp",
    element: <AddEmp />,
    title: "Add Employee",
    mainPage: false,
    showInDashboard: true,
    icon: <AddIcon />,  
    image: addEmployee
    },
  {
    path: "/edit_emp/:id",
    element: <EditEmp />,
    title: "Edit Employee",
    mainPage: false,
    icon: <EditIcon />,  // Using EditIcon directly
  },
  {
    path: "/emp_details/:id",
    element: <EmpDetails />,
    title: "Employee Details",
    mainPage: false,
  },
  {
    path: "/emp_files/:id",
    element: <EmpFilesPage />,
    title: "Employee Files",
    mainPage: false,
  },
  {
    path: "/transaction",
    element: <TransactionTable />,
    title: "Transaction List",
    mainPage: true,
    showInDashboard: true,
    icon: <AddIcon />, 
    image: transaction
  },
  {
    path: "/add_transaction",
    element: <AddTransactions />,
    title: "Add Transaction",
    mainPage: false,
    showInDashboard: true,
    icon: <AddIcon />,  
    image: addTransaction
  },
  {
    path: "/edit_transaction/:id",
    element: <EditTransaction />,
    title: "Edit Transaction",
    mainPage: false,
    icon: <EditIcon />,  // Using EditIcon directly
  },
  {
    path: "/transaction_details/:id",
    element: <TransactionDetails />,
    title: "Transaction Details",
    mainPage: false,
  },
  {
    path: "/transaction_files/:id",
    element: <TransactionFilesPage />,
    title: "Transaction Files",
    mainPage: false,
  },
  {
    path: "/site",
    element: <SiteTable />,
    title: "Site List",
    mainPage: true,
    showInDashboard: true,
    icon: <LocationOnIcon />, 
    image: site
  },
  {
    path: "/add_site",
    element: <AddSite />,
    title: "Add Site",
    mainPage: false,
    showInDashboard: true,
    icon: <AddIcon />, 
    image: addSite
  },
  {
    path: "/edit_site/:id",
    element: <EditSite />,
    title: "Edit Site",
    mainPage: false,
    icon: <EditIcon />,  // Using EditIcon directly
  },
  {
    path: "/site_details/:id",
    element: <SiteDetails />,
    title: "Site Details",
    mainPage: false,
  },
  {
    path: "/site_files/:id",
    element: <SiteFilesPage />,
    title: "Site Files",
    mainPage: false,
  },
  {
    path: "/attendance",
    element: <AttendanceTable />,
    title: "Attendance List",
    mainPage: true,
    showInDashboard: true,
    icon: <AttendanceIcon />, 
    image: attendance
  },
  {
    path: "/add_attendance",
    element: <AddAttendance />,
    title: "Add Attendance",
    mainPage: false,
    showInDashboard: false,
    icon: <AddIcon />, 
  },
  {
    path: "/edit_attendance/:id",
    element: <EditAttendance />,
    title: "Edit Attendance",
    mainPage: false,
    icon: <EditIcon />,  // Using EditIcon directly
  },
  {
    path: "/attendance_details/:id",
    element: <AttendanceDetails />,
    title: "Attendance Details",
    mainPage: false,
  },
  {
    path: "/attendance_files/:id",
    element: <AttendanceFilesPage />,
    title: "Attendance Files",
    mainPage: false,
  },



  {
    path: "/invoice",
    element: <InvoiceTable />,
    title: "Invoice List",
    mainPage: true,
    showInDashboard: true,
    icon: <LocationOnIcon />,  
    image: invoice
  },
  {
    path: "/add_invoice",
    element: <AddInvoice />,
    title: "Add Invoice",
    mainPage: false,
    showInDashboard: false,
    icon: <AddIcon />,  // Using AddIcon directly
  },
  {
    path: "/edit_invoice/:id",
    element: <EditInvoice />,
    title: "Edit Invoice",
    mainPage: false,
    icon: <EditIcon />,  // Using EditIcon directly
  },
  {
    path: "/invoice_details/:id",
    element: <InvoiceDetails />,
    title: "Invoice Details",
    mainPage: false,
  },
  {
    path: "/invoice_files/:id",
    element: <InvoiceFilesPage />,
    title: "Invoice Files",
    mainPage: false,
  },
];

export default routes;
