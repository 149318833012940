export const formatDate = (date) => {
  if (!date || date === "0000-00-00") {
    return; // Return undefined for null, undefined, or "0000-00-00"
  }

  const parsedDate =
    typeof date === "string" ? new Date(date.replace(/-/g, "/")) : new Date(date);

  if (isNaN(parsedDate.getTime())) {
    return; // Return undefined for invalid dates
  }

  const day = parsedDate.getDate().toString().padStart(2, "0");
  const month = parsedDate.toLocaleString("default", { month: "2-digit" });
  const year = parsedDate.getFullYear();

  return `${day}-${month}-${year}`; // Return formatted date
};
