import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Container,
  Divider,
  MenuItem,
  IconButton,
  Grid,
  TableCell,
  Table,
  TableRow,
  TableBody,
  Paper,
  TableContainer,
  TableHead,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Layout from "../../utils/Layout";
import config from "../../config";
import { useToast } from "../../utils/ToastContext";
import letterLogo from "../../letter.png"; // Adjust the path as per your project structure

const AddInvoice = () => {
  const [formData, setFormData] = useState({
    site_name: "",
    amount: "",
    date: new Date().toISOString().split("T")[0], // Default to current date
    remarks: "",
    username: localStorage.username, // Retrieve from localStorage
    session_id: localStorage.session_id, // Retrieve from localStorage
  });
  const [loading, setLoading] = useState(false); // Track loading state
  const navigate = useNavigate();
  const [siteList, setSiteList] = useState([]); // List of site names
  const triggerToast = useToast();
  const [rows, setRows] = useState([
    { description: "", unit: "", price: "", total: "" },
  ]);
  const [totals, setTotals] = useState({ total: 0, netPayable: 0 });
  const [invoiceDetails, setInvoiceDetails] = useState({
    date: "",
    invoiceNo: "",
    purchaseOrder: "",
  });
  const [billingDetails, setBillingDetails] = useState({
    billName: "",
    billAddress: "",
    shipName: "",
    shipAddress: "",
  });
  const [notes, setNotes] = useState(
    "Advance of 50% to be paid before starting the work and the balance amount after completion of work order."
  );

  // Fetch site names from the API
  useEffect(() => {
    const fetchSiteNames = async () => {
      try {
        const response = await axios.post(
          `${config.apiBaseUrl}/employee/get_emp_list.php`,
          {
            username: localStorage.username,
            session_id: localStorage.session_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success) {
          setSiteList(response.data.data); // Assuming the data is an array of { id, surname }
        } else {
          triggerToast("error", response.data.message); // Show error message
        }
      } catch (error) {
        console.error("Error fetching site names:", error);
        triggerToast("error", "Failed to load site names.");
      }
    };

    fetchSiteNames();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) return; // Prevent submitting if already in loading state

    setLoading(true); // Set loading state to true

    try {
      console.log("Data sent:", formData);
      const response = await axios.post(
        `${config.apiBaseUrl}/transaction/add_transaction.php`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Response data:", response.data);

      if (response.data.success) {
        triggerToast("success", "Transaction added successfully!");
        navigate("/transaction");

        // setTimeout(() => {
        //   navigate("/transaction");
        // }, 2000);
      } else {
        triggerToast("error", response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      triggerToast("error", "Something went wrong!");
    }
  };

  const handleBack = () => {
    navigate("/invoice");
  };

  // Handle changes in the table rows
  const handleRowChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;

    // Calculate line total
    if (field === "unit" || field === "price") {
      const unit = parseFloat(updatedRows[index].unit || 0);
      const price = parseFloat(updatedRows[index].price || 0);
      updatedRows[index].total = (unit * price).toFixed(2);
    }

    setRows(updatedRows);

    // If the last row has data, add a new row
    if (
      index === rows.length - 1 &&
      Object.values(updatedRows[index]).some((v) => v)
    ) {
      setRows([
        ...updatedRows,
        { description: "", unit: "", price: "", total: "" },
      ]);
    }

    // Update totals
    updateTotals(updatedRows);
  };

  // Calculate totals
  const updateTotals = (rows) => {
    const total = rows.reduce(
      (sum, row) => sum + parseFloat(row.total || 0),
      0
    );
    setTotals({ total: total.toFixed(2), netPayable: total.toFixed(2) }); // Assuming no tax or discounts
  };

  const handleInvoiceDetailsChange = (field, value) => {
    setInvoiceDetails((prev) => ({ ...prev, [field]: value }));
  };

  const handleBillingDetailsChange = (field, value) => {
    setBillingDetails((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <Layout>
      <Box
        sx={{
          maxWidth: "210mm",
          margin: "0 auto",
          padding: "5mm",
          backgroundColor: "#fff",
          boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
          minHeight: "297mm",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {/* Header Section */}
        <Box>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            mb={4}
          >
            {/* Logo Section */}
            <Grid item xs={7}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: "100%",
                  height: "90px",
                  overflow: "hidden",
                }}
              >
                <img
                  src={letterLogo}
                  alt="Logo"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
            </Grid>

            {/* Invoice Details Section */}
            <Grid item xs={5} textAlign="right">
              {/* Date */}
              <Table sx={{ width: "100%", borderCollapse: "collapse" }}>
                <TableBody>
                  {/* Invoice Header */}
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      align="center"
                      sx={{
                        backgroundColor: "lightblue", // Optional background for distinction
                        fontWeight: "bold",
                        fontSize: "1.2rem", // Adjust size
                        color: "blue",
                        height: "10px", // Set height for the row
                        padding: "2px", // Adjust padding as needed
                      }}
                    >
                      Invoice
                    </TableCell>
                  </TableRow>

                  {/* Date Row */}
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{
                        width: "60%", // Left cell 60% width
                        border: "none",
                        height: "10px", // Define height
                        paddingRight: "0px",
                        paddingLeft: "10px",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "normal" }}>
                        Date:
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        width: "40%", // Right cell 40% width
                        border: "none", // Add border
                        height: "10px", // Define height
                        paddingRight: "0px",
                        paddingLeft: "10px",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      <TextField
                        variant="standard"
                        value={invoiceDetails.date}
                        placeholder="Enter Date"
                        onChange={(e) =>
                          handleInvoiceDetailsChange("date", e.target.value)
                        }
                        type="date"
                        InputProps={{
                          disableUnderline: true,
                          sx: { fontWeight: "normal", textAlign: "left" },
                        }}
                        fullWidth
                      />
                    </TableCell>
                  </TableRow>

                  {/* Invoice Number Row */}
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{
                        width: "60%",
                        border: "none",
                        height: "10px",
                        paddingRight: "0px",
                        paddingLeft: "10px",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "normal" }}>
                        Invoice #:
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        width: "40%",
                        border: "1px solid black",
                        height: "10px",
                        paddingRight: "0px",
                        paddingLeft: "10px",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      <TextField
                        variant="standard"
                        value={invoiceDetails.invoiceNo}
                        placeholder="Enter Invoice #"
                        onChange={(e) =>
                          handleInvoiceDetailsChange(
                            "invoiceNo",
                            e.target.value
                          )
                        }
                        InputProps={{
                          disableUnderline: true,
                          sx: { fontWeight: "normal", textAlign: "left" },
                        }}
                        fullWidth
                      />
                    </TableCell>
                  </TableRow>

                  {/* Purchase Order Row */}
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{
                        width: "60%",
                        border: "none",
                        height: "10px",
                        paddingRight: "0px",
                        paddingLeft: "10px",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "normal" }}>
                        Purchase Order #:
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        width: "40%",
                        border: "1px solid black",
                        height: "10px",
                        paddingRight: "0px",
                        paddingLeft: "10px",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      <TextField
                        variant="standard"
                        value={invoiceDetails.purchaseOrder}
                        placeholder="Enter PO #"
                        onChange={(e) =>
                          handleInvoiceDetailsChange(
                            "purchaseOrder",
                            e.target.value
                          )
                        }
                        InputProps={{
                          disableUnderline: true,
                          sx: { fontWeight: "normal", textAlign: "left" },
                        }}
                        fullWidth
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>

          {/* Billing and Shipping Details */}
          <Table sx={{ width: "100%", borderCollapse: "collapse" }}>
            <TableBody>
              {/* Header Row */}
              <TableRow>
                <TableCell
                  colSpan={1}
                  align="left"
                  sx={{
                    backgroundColor: "lightblue", // Blue background for headings
                    color: "white", // Text color for blue background
                    fontWeight: "bold",
                    fontSize: "1.2rem", // Adjust size
                    height: "20px",
                    width: "50px", // Set height for header
                    padding: "2px", // Adjust padding as needed
                  }}
                >
                  Bill To
                </TableCell>

                <TableCell
                  colSpan={2}
                  align="left"
                  sx={{
                    backgroundColor: "lightblue", // Blue background for headings
                    color: "white", // Text color for blue background
                    fontWeight: "bold",
                    fontSize: "1.2rem", // Adjust size
                    height: "20px", // Set height for header
                    padding: "2px", // Adjust padding as needed
                  }}
                >
                  Ship To (If Different)
                </TableCell>
              </TableRow>

              {/* Name and Address Fields */}
              <TableRow>
                {/* Bill To Name and Address */}
                <TableCell
                  align="left"
                  sx={{
                    width: "40%",
                    border: "none",
                    height: "10px",
                    paddingRight: "0px",
                    paddingLeft: "10px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  <TextField
                    variant="standard"
                    placeholder="Billing Name"
                    // label="Name"
                    margin="dense"
                    value={billingDetails.billName}
                    onChange={(e) =>
                      handleBillingDetailsChange("billName", e.target.value)
                    }
                    InputProps={{
                      disableUnderline: true,
                      sx: { fontWeight: "normal", textAlign: "left" },
                    }}
                    fullWidth
                  />
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    width: "40%",
                    border: "none",
                    height: "10px",
                    paddingRight: "0px",
                    paddingLeft: "10px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  <TextField
                    variant="standard"
                    placeholder="Shipping Name"
                    // label="Name"
                    margin="dense"
                    value={billingDetails.shipName}
                    onChange={(e) =>
                      handleBillingDetailsChange("shipName", e.target.value)
                    }
                    InputProps={{
                      disableUnderline: true,
                      sx: { fontWeight: "normal", textAlign: "left" },
                    }}
                    fullWidth
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                {/* Bill To Address */}
                <TableCell
                  align="left"
                  sx={{
                    width: "40%",
                    border: "none",
                    height: "10px",
                    paddingRight: "0px",
                    paddingLeft: "10px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  <TextField
                    variant="standard"
                    placeholder="Billing Address"
                    margin="dense"
                    multiline
                    rows={3}
                    value={billingDetails.billAddress}
                    onChange={(e) =>
                      handleBillingDetailsChange("billAddress", e.target.value)
                    }
                    InputProps={{
                      disableUnderline: true,
                      sx: { fontWeight: "normal", textAlign: "left" },
                    }}
                    fullWidth
                  />
                </TableCell>
                {/* Ship To Address */}
                <TableCell
                  align="left"
                  sx={{
                    width: "40%",
                    border: "none",
                    height: "10px",
                    paddingRight: "0px",
                    paddingLeft: "10px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  <TextField
                    variant="standard"
                    placeholder="Shipping Address"
                    margin="dense"
                    multiline
                    rows={3}
                    value={billingDetails.shipAddress}
                    onChange={(e) =>
                      handleBillingDetailsChange("shipAddress", e.target.value)
                    }
                    InputProps={{
                      disableUnderline: true,
                      sx: { fontWeight: "normal", textAlign: "left" },
                    }}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>

        {/* Table for Items */}
        <TableContainer component={Paper} sx={{ mb: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "60%" }}>Description</TableCell>
                <TableCell sx={{ width: "10%" }}>Unit</TableCell>
                <TableCell sx={{ width: "15%" }}>Price</TableCell>
                <TableCell sx={{ width: "15%" }}>Line Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextField
                      fullWidth
                      variant="standard"
                      value={row.description}
                      placeholder="Description"
                      onChange={(e) =>
                        handleRowChange(index, "description", e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      variant="standard"
                      value={row.unit}
                      type="number"
                      placeholder="Unit"
                      onChange={(e) =>
                        handleRowChange(index, "unit", e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      variant="standard"
                      value={row.price}
                      type="number"
                      placeholder="Price"
                      onChange={(e) =>
                        handleRowChange(index, "price", e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      variant="standard"
                      value={row.total}
                      placeholder="Total"
                      disabled
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Notes and Totals */}
        <Grid container spacing={2} alignItems="flex-start" mb={4}>
          <Grid item xs={8}>
            <Typography variant="h6">Notes and Instructions</Typography>
            <TextField
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" flexDirection="column" gap={2}>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{
                    width: "50%",
                    border: "none",
                    height: "10px",
                    paddingRight: "0px",
                    paddingLeft: "10px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "normal" }}>
                    Sub Total #:
                  </Typography>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    width: "50%",
                    border: "1px solid black",
                    height: "10px",
                    paddingRight: "0px",
                    paddingLeft: "10px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  <TextField
                    variant="standard"
                    value={invoiceDetails.subtotal}
                    placeholder="Sub Total #"
                    onChange={(e) =>
                      handleInvoiceDetailsChange("subtotal", e.target.value)
                    }
                    InputProps={{
                      disableUnderline: true,
                      sx: { fontWeight: "normal", textAlign: "left" },
                    }}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{
                    width: "50%",
                    border: "none",
                    height: "10px",
                    paddingRight: "0px",
                    paddingLeft: "10px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "normal" }}>
                    Net Payable #:
                  </Typography>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    width: "50%",
                    border: "1px solid black",
                    height: "10px",
                    paddingRight: "0px",
                    paddingLeft: "10px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  <TextField
                    variant="standard"
                    value={totals.netPayable}
                    placeholder="netPayable #"
                    onChange={(e) =>
                      handleInvoiceDetailsChange("netPayable", e.target.value)
                    }
                    InputProps={{
                      disableUnderline: true,
                      sx: { fontWeight: "normal", textAlign: "left" },
                    }}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
            </Box>
          </Grid>
        </Grid>

        {/* Footer */}
        <Box textAlign="center" mt="auto" mb={2}>
          <Typography>
            Make all checks payable to RAWAFID AL SEEB TRADING
          </Typography>
          <Typography>Thank you for your business!</Typography>
        </Box>
      </Box>
    </Layout>
  );
};

export default AddInvoice;
