import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TableSortLabel,
  Fab,
  Checkbox,
  MenuItem,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";
import Icons from "../../utils/icon";
import config from "../../config";
import Layout from "../../utils/Layout";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";

const months = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const AttendanceTable = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const navigate = useNavigate();
  // Get the current month and year
  const currentDate = new Date();
  const currentMonth = (currentDate.getMonth() + 1).toString(); // Add 1 since months are 0-indexed
  const currentYear = currentDate.getFullYear().toString();

  // Initialize month and year state with current values
  const [month, setMonth] = useState(currentMonth); // Default to current month
  const [year, setYear] = useState(currentYear); // Default to current year
  const [employeeNames, setEmployeeNames] = useState({});
  const [siteNames, setSiteNames] = useState({});

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const username = localStorage.getItem("username");
      const session_id = localStorage.getItem("session_id");

      if (username && session_id) {
        try {
          setLoading(true);
          const response = await fetch(
            `${config.apiBaseUrl}/attendance/get_all_attendance.php`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                username,
                session_id,
                search: searchQuery || null,
              }),
            }
          );

          const result = await response.json();
          console.log("Fetched attendance data:", result); // Log

          if (result.success) {
            setData(result.data);
          } else {
            console.error("Error fetching attendance:", result.message);
          }
        } catch (error) {
          console.error("Error fetching attendance:", error);
        } finally {
          setLoading(false);
        }
      } else {
        navigate("/");
      }
    };

    fetchData();
  }, [searchQuery]);

  useEffect(() => {
    if (data.length > 0) {
      const empIds = data.map((attendance) => attendance.emp_id);
      const siteIds = data.map((attendance) => attendance.site_id);
      fetchEmployeeNames(empIds);
      fetchSiteNames(siteIds);
    }
  }, [data]);

  const fetchEmployeeNames = async (empIds) => {
    const uniqueEmpIds = [...new Set(empIds)];
    const names = {};
    const username = localStorage.getItem("username");
    const session_id = localStorage.getItem("session_id");

    if (!username || !session_id) {
      console.error("Username or session ID is missing.");
      return; // Exit if username or session_id is not available
    }

    for (const empId of uniqueEmpIds) {
      try {
        // Ensure empId is not empty before making the request
        if (!empId) {
          console.error(`Employee ID ${empId} is invalid or missing.`);
          continue; // Skip this iteration if empId is invalid
        }

        const response = await fetch(
          `${config.apiBaseUrl}/employee/get_emp_name.php`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              empId: empId, // Make sure empId is included
              username: username, // Include username
              session_id: session_id, // Include session_id
            }),
          }
        );
        const result = await response.json();

        console.log(`Fetched employee name for ${empId}:`, result); // Log the fetched name

        if (result.success) {
          names[empId] = result.data.surname; // Assuming surname is returned
        }
      } catch (error) {
        console.error(`Error fetching employee name for ${empId}:`, error);
      }
    }

    setEmployeeNames(names); // Store the fetched names
    console.log("All fetched employee names:", names); // Log the entire names object
  };

  const fetchSiteNames = async (siteIds) => {
    const uniqueSiteIds = [...new Set(siteIds)];
    const names = {};
    const username = localStorage.getItem("username");
    const session_id = localStorage.getItem("session_id");

    if (!username || !session_id) {
      console.error("Username or session ID is missing.");
      return; // Exit if username or session_id is not available
    }

    for (const siteId of uniqueSiteIds) {
      if (!siteId) {
        console.error(`Site ID ${siteId} is invalid or missing.`);
        continue;
      }

      try {
        // Ensure siteId is not empty before making the request
        if (!siteId) {
          console.error(`Site ID ${siteId} is invalid or missing.`);
          continue; // Skip this iteration if siteId is invalid
        }

        const response = await fetch(
          `${config.apiBaseUrl}/site/get_site_name.php`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              siteId: siteId, // Make sure siteId is included
              username: username, // Include username
              session_id: session_id, // Include session_id
            }),
          }
        );
        const result = await response.json();

        console.log(`Fetched site name for ${siteId}:`, result); // Log the fetched name

        if (result.success) {
          names[siteId] = result.data.site_name; // Assuming surname is returned
        }
      } catch (error) {
        console.error(`Error fetching site name for ${siteId}:`, error);
      }
    }

    setSiteNames(names); // Store the fetched names
    console.log("All fetched site names:", names); // Log the entire names object
  };

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const handleCheckboxChange = (id) => {
    setSelectedData((prev) =>
      prev.includes(id) ? prev.filter((dataId) => dataId !== id) : [...prev, id]
    );
  };

  // Move to the previous month
  const handlePrevMonth = () => {
    let prevMonth = parseInt(month) - 1;
    let prevYear = year;

    if (prevMonth < 1) {
      prevMonth = 12;
      prevYear = (parseInt(year) - 1).toString();
    }

    setMonth(prevMonth.toString());
    setYear(prevYear);
  };

  // Move to the next month
  const handleNextMonth = () => {
    let nextMonth = parseInt(month) + 1;
    let nextYear = year;

    if (nextMonth > 12) {
      nextMonth = 1;
      nextYear = (parseInt(year) + 1).toString();
    }

    setMonth(nextMonth.toString());
    setYear(nextYear);
  };

  // Filtered data based on month, year, and search query
  const filteredData = data.filter((item) => {
    const itemDate = new Date(item.date);
    const matchesMonth = month
      ? itemDate.getMonth() + 1 === parseInt(month)
      : true;
    const matchesYear = year
      ? itemDate.getFullYear().toString() === year
      : true;
    const matchesSearch = item.site_id
      .toLowerCase()
      .includes(searchQuery.toLowerCase());

    return matchesMonth && matchesYear && matchesSearch;
  });

  const sortedData = filteredData.sort((a, b) => {
    const order = sortConfig.direction === "asc" ? 1 : -1;
    if (a[sortConfig.key] < b[sortConfig.key]) return -order;
    if (a[sortConfig.key] > b[sortConfig.key]) return order;
    return 0;
  });

  const handleExportPDF = () => {
    if (selectedData.length === 0) {
      console.warn("No data selected for export.");
      return;
    }
    const doc = new jsPDF();
    const tableData = selectedData.map((id) => {
      const attendance = data.find((item) => item.id === id);
      return [
        attendance.id,
        attendance.site_name,
        attendance.amount,
        attendance.date,
        attendance.remarks,
      ];
    });

    doc.text("Attendance Report", 14, 10);
    doc.autoTable({
      head: [
        [
          "Id",
          "Site Id",
          "Present",
          "Mess",
          "Wifi",
          "Advance",
          "Visa",
          "Total Reduction",
          "Visa Remarks",
        ],
      ],
      body: tableData,
    });

    doc.save("Attendance_List.pdf");
  };

  const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: "4px",
          },
        },
      },
    },
  });

  const cellStyles = {
    border: "none",
    boxShadow: "0px 0px 0px 0.5px rgba(127, 147, 184, 0.2)",
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          height: "90vh",
          maxWidth: "md",
          margin: "auto",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            position: "sticky",
            top: 0,
            zIndex: 2,
            backgroundColor: "white",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            display: "flex",
            justifyContent: "Center",
            alignItems: "center",
            paddingRight: 20,
            paddingLeft: 20,
            paddingBottom: 1,
            paddingTop: 1,
            gap: 2,
          }}
        >
          <Fab
            color="primary"
            aria-label="add"
            onClick={handleExportPDF}
            disabled={selectedData.length === 0}
            sx={{
              borderRadius: 1,
              width: 35,
              height: 35,
              minHeight: "unset",
            }}
          >
            <Icons.Download />
          </Fab>

          {/* Previous Month Button */}
          <Fab
            color="primary"
            aria-label="previous"
            onClick={handlePrevMonth}
            sx={{
              borderRadius: 1,
              width: 35,
              height: 35,
              minHeight: "unset",
            }}
          >
            <ArrowBackIcon />
          </Fab>

          <TextField
            variant="outlined"
            select
            label="Month"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            size="small"
            sx={{ flexGrow: 0.5, marginLeft: 1 }}
          >
            {months.map((month) => (
              <MenuItem key={month.value} value={month.value}>
                {month.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            variant="outlined"
            select
            label="Year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            size="small"
            sx={{ flexGrow: 0.5, marginLeft: 1 }}
          >
            {[...Array(3)].map((_, index) => {
              const yearOption = (parseInt(currentYear) - index).toString();
              return (
                <MenuItem key={yearOption} value={yearOption}>
                  {yearOption}
                </MenuItem>
              );
            })}
          </TextField>

          <Fab
            color="primary"
            aria-label="next"
            onClick={handleNextMonth}
            sx={{
              borderRadius: 1,
              width: 35,
              height: 35,
              minHeight: "unset",
            }}
          >
            <ArrowForwardIcon />
          </Fab>

          <TextField
            variant="outlined"
            placeholder="Search Attendance..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            size="small"
            sx={{ flexGrow: 0.5, marginLeft: 1 }}
          />
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => navigate("/add_attendance")}
            sx={{
              borderRadius: 1,
              width: 35,
              height: 35,
              minHeight: "unset",
            }}
          >
            <Icons.Add />
          </Fab>
        </Box>

        {/* Table Container */}
        <ThemeProvider theme={theme}>
          <TableContainer
            sx={{
              flexGrow: 1,
              height: "calc(100vh - 90px)",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "5px",
                height: "5px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              },
            }}
          >
            <Table
              stickyHeader
              sx={{
                tableLayout: "auto",
                width: "md",
              }}
            >
              {" "}
              <TableHead>
                <TableRow
                  sx={{
                    bgcolor: "primary.main",
                    color: "white",
                    zIndex: 1000,
                  }}
                >
                  {" "}
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selectedData.length > 0 &&
                        selectedData.length < sortedData.length
                      }
                      checked={
                        selectedData.length === sortedData.length &&
                        sortedData.length > 0
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedData(sortedData.map((data) => data.id)); // Select all
                        } else {
                          setSelectedData([]); // Deselect all
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === "name"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("name")}
                    >
                      ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Site</TableCell>
                  <TableCell>Employee</TableCell>
                  <TableCell>Present</TableCell>
                  <TableCell>Mess</TableCell>
                  <TableCell>Wifi</TableCell>
                  <TableCell>Advance</TableCell>
                  <TableCell>Visa</TableCell>
                  <TableCell>Deduction</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <div className="loading-container">
                  <div className="spinner"></div>
                  <p>Loading, please wait...</p>
                </div>
              ) : (
                <TableBody>
                  {sortedData.map((data) => (
                    <TableRow
                      key={data.id}
                      sx={{
                        "&:nth-of-type(odd)": { bgcolor: "grey.200" }, // Alternate row colors
                      }}
                    >
                      <TableCell>
                        <Checkbox
                          checked={selectedData.includes(data.id)}
                          onChange={() => handleCheckboxChange(data.id)}
                        />
                      </TableCell>
                      <TableCell sx={cellStyles}>{data.id}</TableCell>
                      {/* <TableCell sx={cellStyles}>{data.site_id}</TableCell> */}
                      <TableCell sx={cellStyles}>
                        {siteNames[data.site_id] || "Loading..."}
                      </TableCell>{" "}
                      <TableCell sx={cellStyles}>
                        {employeeNames[data.emp_id] || "Loading..."}
                      </TableCell>{" "}
                      <TableCell sx={cellStyles}>{data.present}</TableCell>
                      <TableCell sx={cellStyles}>{data.mess}</TableCell>
                      <TableCell sx={cellStyles}>{data.wifi}</TableCell>
                      <TableCell sx={cellStyles}>{data.advance}</TableCell>
                      <TableCell sx={cellStyles}>{data.visa}</TableCell>
                      <TableCell sx={cellStyles}>
                        {data.total_reduction}
                      </TableCell>
                      <TableCell sx={cellStyles}>
                        <Fab
                          color="primary"
                          aria-label="edit"
                          onClick={() =>
                            navigate(`/edit_attendance/${data.id}`)
                          }
                          sx={{
                            width: 35,
                            height: 35,
                            borderRadius: "4px",
                            minHeight: "unset",
                            minWidth: "unset",
                            zIndex: 1,
                          }}
                        >
                          <Icons.EditNoteIcon sx={{ fontSize: 20 }} />
                        </Fab>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </ThemeProvider>
      </Box>
    </Layout>
  );
};

export default AttendanceTable;
