import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  AppBar,
  Toolbar,
  Button,
  Drawer,
  IconButton,
  Divider,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation, useNavigate } from "react-router-dom";
import { handleLogout } from "./auth";
import { useTheme } from "@mui/material/styles";
import config from "../config";
import Icons from "./icon";
import { useToast } from "./ToastContext";
import routes from "../routes";

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const triggerToast = useToast(); // Access the triggerToast function
  const location = useLocation();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [isDrawerOpen, setIsDrawerOpen] = useState(() => {
    // Read the initial state from local storage
    return JSON.parse(localStorage.getItem("drawerOpen")) || false;
  });
  const [pageTitle, setPageTitle] = useState("Dashboard");
  const [username, setUsername] = useState("");

  // Set the username on initial load
  useEffect(() => {
    const storedUser = localStorage.getItem("username");
    setUsername(storedUser || "Guest");
  }, []);

  useEffect(() => {
    const matchedRoute = routes.find((route) => {
      const pathRegex = new RegExp(
        "^" + route.path.replace(/:\w+/g, "([\\w-]+)") + "$"
      );
      return pathRegex.test(location.pathname);
    });
    if (matchedRoute) {
      setPageTitle(matchedRoute.title);
    } else {
      setPageTitle("Dashboard");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isLargeScreen) {
      const savedState = JSON.parse(localStorage.getItem("drawerOpen"));
      setIsDrawerOpen(savedState !== null ? savedState : true);
    } else {
      setIsDrawerOpen(false);
    }
  }, [isLargeScreen]);

  const toggleDrawer = () => {
    const newState = !isDrawerOpen;
    setIsDrawerOpen(newState);
    if (isLargeScreen) {
      localStorage.setItem("drawerOpen", JSON.stringify(newState));
    }
  };

  const handleNavigation = (path) => {
    navigate(path);
    if (!isLargeScreen) setIsDrawerOpen(false);
  };

  const onLogout = async () => {
    await handleLogout();
    navigate("/");
  };

  const menuItems = routes
    .filter((route) => route.mainPage)
    .map((route) => ({
      text: route.title,
      icon: route.icon,
      path: route.path,
    }));

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      {/* Header */}
      <AppBar
        position="fixed"
        sx={{ backgroundColor: config.layoutColors.appBar, zIndex: 1300 }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton color="inherit" onClick={toggleDrawer} edge="start">
              <MenuIcon />
            </IconButton>
            <Box
              component="img"
              src="/assets/logo.png"
              alt="Company Logo"
              sx={{ height: 40, marginLeft: 1 }}
            />
          </Box>
          <Typography variant="h6" sx={{ textAlign: "center", flexGrow: 1 }}>
            {pageTitle}
          </Typography>
          <Button color="inherit" startIcon={<LogoutIcon />} onClick={onLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      {/* Sidebar Drawer */}
      <Drawer
        variant={isLargeScreen ? "persistent" : "temporary"}
        anchor="left"
        open={isDrawerOpen}
        onClose={!isLargeScreen ? toggleDrawer : undefined}
        sx={{
          "& .MuiDrawer-paper": {
            width: 250,
            backgroundColor: config.layoutColors.drawer,
            color: "white",
            top: isLargeScreen ? "64px" : 0,
          },
        }}
      >
        {/* User Info */}
        <Box sx={{ textAlign: "center", p: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
            Welcome
          </Typography>
          <Typography variant="subtitle2">{username}</Typography>
        </Box>
        <Divider sx={{ borderColor: "white", opacity: 0.5 }} />

        {/* Menu Buttons */}
        <Box sx={{ p: 2 }}>
          {menuItems.map((item) => (
            <Button
              key={item.text}
              fullWidth
              onClick={() => handleNavigation(item.path)}
              startIcon={item.icon}
              sx={{
                mb: 2,
                justifyContent: "flex-start",
                color: "white",
                textTransform: "none",
                padding: 1.5,
                backgroundColor:
                  location.pathname === item.path
                    ? "rgba(255, 255, 255, 0.2)"
                    : "transparent",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.3)",
                },
              }}
            >
              {item.text}
            </Button>
          ))}
        </Box>

        {/* Logout Button */}
        <Box sx={{ textAlign: "center", mt: "auto", pb: 2 }}>
          <Button
            fullWidth
            color="inherit"
            startIcon={<LogoutIcon />}
            onClick={onLogout}
            sx={{
              textTransform: "none",
              padding: 1.5,
              "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" },
            }}
          >
            Logout
          </Button>
        </Box>
      </Drawer>

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          marginLeft: isLargeScreen && isDrawerOpen ? "250px" : 0,
          padding: 2,
          marginTop: "64px",
          backgroundColor: config.layoutColors.background,
          overflowY: "hidden",
          transition: "none",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
