import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Button,
  Paper,
  IconButton,
  Grid,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../config";
import Layout from "../../utils/Layout";
import Icons from "../../utils/icon";
import { useToast } from "../../utils/ToastContext";

// Optional: Snackbar for Toast-like notifications
import MuiAlert from "@mui/material/Alert";

const AlertToast = React.forwardRef(function AlertToast(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const QuoteFilesPage = () => {
  const { id } = useParams(); // Get the quote ID from the route params
  const [quoteFile, setQuoteFile] = useState(null);
  const [lpoFile, setLpoFile] = useState(null);
  const [invoiceFile, setInvoiceFile] = useState(null);
  const [quoteDetails, setQuoteDetails] = useState({});

  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const triggerToast = useToast();

  useEffect(() => {
    const fetchQuote = async () => {
      try {
        setLoading(true); // Set loading to true before starting the fetch
        const requestData = {
          id,
          username: localStorage.getItem("username"),
          session_id: localStorage.getItem("session_id"),
        };

        const response = await axios.post(
          `${config.apiBaseUrl}/get_by_id.php`,
          requestData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success) {
          setQuoteDetails(response.data.data);
        } else {
          triggerToast("error", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching quote:", error);
        triggerToast("error", "Failed to load quote details.");
      } finally {
        setLoading(false); // Set loading to false after fetch is complete
      }
    };

    fetchQuote();
  }, [id]);

  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    if (type === "quote") setQuoteFile(file);
    if (type === "lpo") setLpoFile(file);
    if (type === "invoice") setInvoiceFile(file);
  };

  const handleFileDelete = (type) => {
    if (type === "quote") setQuoteFile(null);
    if (type === "lpo") setLpoFile(null);
    if (type === "invoice") setInvoiceFile(null);
  };

  const getUpdatedFileName = (file, type) => {
    if (!file) return null;
    const extension = file.name.split(".").pop();
    return `${id}_${type}.${extension}`;
  };

  const handleFileUpload = async () => {
    const username = localStorage.getItem("username");
    const session_id = localStorage.getItem("session_id");

    if (!username || !session_id) {
      triggerToast("error", "User is not authenticated!");
      return;
    }

    const files = [
      { file: quoteFile, name: `${id}_quote`, key: "quote_file" },
      { file: lpoFile, name: `${id}_lpo`, key: "lpo_file" },
      { file: invoiceFile, name: `${id}_invoice`, key: "invoice_file" },
    ];

    try {
      setLoading(true);

      for (const { file, name, key } of files) {
        if (file) {
          const formData = new FormData();
          formData.append("id", id);
          formData.append("session_id", session_id);
          formData.append("username", username);
          formData.append("file_name", `${name}.${file.name.split(".").pop()}`); // Add extension
          formData.append("file", file);
          formData.append("file_type", key); // Ensure the file type is included

          const response = await fetch(`${config.apiBaseUrl}/upload_file.php`, {
            method: "POST",
            body: formData,
          });

          const result = await response.json();
          if (!result.success) {
            throw new Error(result.message || "File upload failed");
          }
        }
      }

      triggerToast( "success", "Files uploaded successfully!" );
      navigate(`/quote_details/${id}`);
    } catch (error) {
      console.error("Error uploading files:", error);
      triggerToast("error", "Error uploading files. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const renderPreview = (file, type) => {
    const updatedFileName = getUpdatedFileName(file, type);
    if (!file) return null;

    const isImage = file.type.startsWith("image/");
    const isPdf = file.type === "application/pdf";

    return (
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative", // Ensure position is relative for FAB
          height: "250px",
          width: "250px",
          textAlign: "center",
          p: 2,
        }}
      >
        <Typography variant="body2" gutterBottom>
          {updatedFileName}
        </Typography>

        {isImage && (
          <img
            src={URL.createObjectURL(file)}
            alt={updatedFileName}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        )}

        {isPdf && (
          <Icons.PictureAsPdfIcon
            style={{ fontSize: "6rem", color: "#d32f2f" }}
          />
        )}

        <Box sx={{ position: "absolute", bottom: 10, right: 10 }}>
          <IconButton
            color="secondary"
            onClick={() => handleFileDelete(type)}
            sx={{ ml: 1 }}
          >
            <Icons.DeleteIcon />
          </IconButton>
          <Button
            variant="contained"
            color="primary"
            component="label"
            startIcon={<Icons.EditIcon />}
          >
            <input
              type="file"
              hidden
              onChange={(e) => handleFileChange(e, type)}
            />
          </Button>
        </Box>
      </Paper>
    );
  };

  const renderUploadButton = (file, type, label) => {
    if (file) {
      return renderPreview(file, type);
    }

    return (
      <Button
        variant="outlined"
        startIcon={<Icons.CloudUploadIcon />}
        component="label"
        sx={{
          height: "250px",
          width: "250px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          p: 2,
        }}
      >
        {label}
        <input type="file" hidden onChange={(e) => handleFileChange(e, type)} />
      </Button>
    );
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "start",
          height: "100vh",
          p: 3,
        }}
      >
        <Typography variant="h4" gutterBottom>
          Upload Files for Quote ID: {id}
        </Typography>

        <form
          onSubmit={handleFileUpload}
          style={{ width: "100%", maxWidth: "400px" }}
        >
          <Typography variant="body1" gutterBottom>
            Upload the necessary files below:
          </Typography>

          <Grid
            container
            spacing={3}
            gap={3}
            sx={{
              mt: 2,
              gridTemplateColumns: "repeat(4, 1fr)",
              display: "grid",
            }}
          >
            {renderUploadButton(quoteFile, "quote", "Upload Quote File")}
            {renderUploadButton(lpoFile, "lpo", "Upload LPO File")}
            {renderUploadButton(invoiceFile, "invoice", "Upload Invoice File")}
          </Grid>

          <Button
            variant="contained"
            color="primary"
            onClick={handleFileUpload}
            disabled={loading}
            sx={{ mt: 3 }}
          >
            {loading ? "Uploading..." : "Upload Files"}
          </Button>
        </form>
      </Box>
    </Layout>
  );
};

export default QuoteFilesPage;
