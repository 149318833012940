import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Container,
  IconButton,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Layout from "../../utils/Layout";
import config from "../../config";
import { useToast } from "../../utils/ToastContext";

const AddQuote = () => {
  const [formData, setFormData] = useState({
    ref_no: "", // Added ref_no
    quote_name: "",
    quote_date: "",
    quoted_amount: "",
    description: "",
    submitted_to: "",
    status: "Pending",

    username: localStorage.username, // Retrieve from localStorage
    session_id: localStorage.session_id, // Retrieve from localStorage
  });

  const navigate = useNavigate();
  const triggerToast = useToast();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log("Data sent:", formData);
      const response = await axios.post(
        `${config.apiBaseUrl}/add_quote.php`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Response data:", response.data);

      if (response.data.success) {
        triggerToast("success",response.data.message); // Show success message
        navigate("/quotes");
        // setTimeout(() => {
        // }, 2000);
      } else {
        triggerToast("error",response.data.message); // Show error message
      }
    } catch (error) {
      console.error("Error:", error);
      triggerToast("error","Something went wrong!"); // General error
    }
  };

  const handleBack = () => {
    navigate("/quotes");
  };

  return (
    <Layout>
      <Container maxWidth="sm">
        <Box display="flex" alignItems="center" mb={2}>
          <IconButton onClick={handleBack}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h5" ml={1}>
            Add New Quote
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <TextField
              label="Reference Number"
              variant="outlined"
              fullWidth
              name="ref_no"
              value={formData.ref_no}
              onChange={handleChange}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Quote Name"
              variant="outlined"
              fullWidth
              name="quote_name"
              value={formData.quote_name}
              onChange={handleChange}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Quote Date"
              type="date"
              variant="outlined"
              fullWidth
              name="quote_date"
              value={formData.quote_date}
              onChange={handleChange}
              required
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Quoted Amount"
              type="number"
              variant="outlined"
              fullWidth
              name="quoted_amount"
              value={formData.quoted_amount}
              onChange={handleChange}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Description"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Submitted To"
              variant="outlined"
              fullWidth
              name="submitted_to"
              value={formData.submitted_to}
              onChange={handleChange}
            />
          </Box>

          <Box mt={2}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Add Quote
            </Button>
          </Box>
        </form>
      </Container>
    </Layout>
  );
};

export default AddQuote;
