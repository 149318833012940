import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Container,
  Divider,
  MenuItem,
  IconButton,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Layout from "../../utils/Layout";
import config from "../../config";
import { useToast } from "../../utils/ToastContext";

const AddSite = () => {
  const [formData, setFormData] = useState({
    site_name: "",
    site_location: "",
    site_map: "",
    site_amount: "",
    start_date: "",
    end_date: "",
    client_name: "",
    client_number: "",
    client_location: "",
    Description: "",
    Remarks: "",
    username: localStorage.username, // Retrieve from localStorage
    session_id: localStorage.session_id, // Retrieve from localStorage
  });

  const navigate = useNavigate();
  const triggerToast = useToast();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log("Data sent:", formData);
      const response = await axios.post(
        `${config.apiBaseUrl}/site/add_site.php`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Response data:", response.data);

      if (response.data.success) {
        triggerToast("success", response.data.message); // Show success message
        navigate("/site");
      } else {
        triggerToast("error", response.data.message); // Show error message
      }
    } catch (error) {
      console.error("Error:", error);
      triggerToast("error", "Something went wrong!"); // General error
    }
  };

  const handleBack = () => {
    navigate("/site");
  };

  return (
    <Layout>
      <Container maxWidth="md">
        <Box display="flex" alignItems="center" mb={3}>
          <IconButton onClick={handleBack}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h5" ml={1}>
            Add New Site
          </Typography>
        </Box>

        <Container
          maxWidth="md"
          style={{
            overflow: "auto",
            maxHeight: "80vh",
            scrollbarWidth: "thin",
            scrollbarColor: "lightgrey transparent",
            padding: "16px",
            border: "1px solid #e0e0e0",
            borderRadius: "8px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#ffffff",
          }}
        >
          <form onSubmit={handleSubmit}>
            {/* Personal Details Section */}
            <Box mb={4}>
              <Typography variant="h6" gutterBottom>
                Site Details
              </Typography>
              <Divider />
              <Box display="flex" flexDirection="column" gap={2} mb={2}>
                {/* Dropdown for Site Names */}
                <TextField
                  // select
                  label="Site Name"
                  variant="outlined"
                  name="site_name"
                  value={formData.site_name}
                  onChange={handleChange}
                  fullWidth
                ></TextField>

                <TextField
                  label="Site Location"
                  variant="outlined"
                  name="site_location"
                  value={formData.site_location}
                  onChange={handleChange}
                  fullWidth
                />

                <TextField
                  label="Site Map"
                  variant="outlined"
                  name="site_map"
                  value={formData.site_map}
                  onChange={handleChange}
                  fullWidth
                />

                <TextField
                  label="Site Amount"
                  variant="outlined"
                  name="site_amount"
                  type="number"
                  value={formData.site_amount}
                  onChange={handleChange}
                  fullWidth
                />

                <TextField
                  label="Start Date"
                  type="date"
                  variant="outlined"
                  name="start_date"
                  value={
                    formData.start_date ||
                    new Date().toISOString().split("T")[0]
                  }
                  onChange={handleChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />

                <TextField
                  label="End Date"
                  type="date"
                  variant="outlined"
                  name="end_date"
                  value={
                    formData.end_date || new Date().toISOString().split("T")[0]
                  }
                  onChange={handleChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />

                <TextField
                  label="Client Name"
                  variant="outlined"
                  name="client_name"
                  value={formData.client_name}
                  onChange={handleChange}
                  fullWidth
                />

                <TextField
                  label="Client Number"
                  variant="outlined"
                  name="client_number"
                  type="tel"
                  value={formData.client_number}
                  onChange={handleChange}
                  fullWidth
                />

                <TextField
                  label="Client Location"
                  variant="outlined"
                  name="client_location"
                  value={formData.client_location}
                  onChange={handleChange}
                  fullWidth
                />

                <TextField
                  label="Description"
                  variant="outlined"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  fullWidth
                />

                <TextField
                  label="Remarks"
                  variant="outlined"
                  name="remarks"
                  value={formData.remarks}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  fullWidth
                />
              </Box>
            </Box>

            {/* Buttons */}
            <Box mt={4} display="flex" justifyContent="space-between">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ flex: 1, marginRight: "8px" }}
              >
                Add Site
              </Button>
              <Button
                type="button"
                variant="outlined"
                color="secondary"
                onClick={handleBack}
                style={{ flex: 1 }}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Container>
      </Container>
    </Layout>
  );
};

export default AddSite;
