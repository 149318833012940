import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Container,
  Divider,
  IconButton,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Layout from "../../utils/Layout";
import config from "../../config";
import { useToast } from "../../utils/ToastContext";

const EditEmp = () => {
  const { id } = useParams(); // Retrieve employee ID from route params
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const triggerToast = useToast();

  const [formData, setFormData] = useState({
    id: id, // Include emp_id for updates
    surname: "",
    given_name: "",
    nationality: "",
    sex: "",
    dob: "",
    place_of_birth: "",
    place_of_issue: "",
    passport_number: "",
    passport_issue_date: "",
    passport_expiry_date: "",
    id_number: "",
    id_issue_date: "",
    id_expiry_date: "",
    company_name: "",
    username: localStorage.username, // Retrieve from localStorage
    session_id: localStorage.session_id, // Retrieve from localStorage
  });

  // Fetch existing employee data for editing
  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        setLoading(true); // Set loading to true before starting the fetch
        const requestData = {
          id,
          username: localStorage.getItem("username"),
          session_id: localStorage.getItem("session_id"),
        };

        console.log("Fetching Quote with ID:", id);
        const response = await axios.post(
          `${config.apiBaseUrl}/employee/get_by_id_emp.php`,
          requestData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success) {
          setFormData({ ...formData, ...response.data.employee });
          console.log(response.data.employee);
        } else {
          triggerToast(
            "error",
            response.data.message || "Failed to fetch employee data"
          );
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
        triggerToast(
          "error",
          "An error occurred while fetching employee data."
        );
      } finally {
        setLoading(false); // Set loading to false after fetch is complete
      }
    };

    fetchEmployeeData();
  }, [id]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const updatedFormData = {
        ...formData,
        id: id,
        username: localStorage.username,
        session_id: localStorage.session_id,
      };

      console.log("Data sent:", updatedFormData);
      const response = await axios.post(
        `${config.apiBaseUrl}/employee/update_emp.php`,
        updatedFormData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Response data:", response.data);

      if (response.data.success) {
        triggerToast("success", response.data.message); // Show success message
        // Redirect to /employees after a short delay

        navigate("/emp");
      } else {
        triggerToast("error", response.data.message); // Show error message
      }
    } catch (error) {
      console.error("Error:", error);
      triggerToast("error", "Something went wrong!"); // General error
    }
  };

  const handleBack = () => {
    navigate("/emp");
  };

  const handleFile = () => {
    navigate(`/emp_files/${id}`);
  };

  return (
    <Layout>
      <Container
        maxWidth="md"
        style={{
          overflow: "auto",
          maxHeight: "90vh",
          marginBottom: 10,
          scrollbarWidth: "thin",
          scrollbarColor: "lightgrey transparent",
          padding: "16px",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#ffffff",
        }}
      >
        <Box display="flex" alignItems="center" mb={3}>
          <IconButton onClick={handleBack}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h5" ml={1}>
            Edit Employee
          </Typography>
        </Box>

        <form onSubmit={handleSubmit}>
          {/* Personal Details Section */}
          <Box mb={4}>
            <Typography variant="h6" gutterBottom>
              Personal Details
            </Typography>
            <Divider />
            <Box display="flex" mt={2} mb={2}>
              <TextField
                label="Surname"
                variant="outlined"
                name="surname"
                value={formData.surname}
                onChange={handleChange}
                fullWidth
                slotProps={{ inputLabel: { shrink: !!formData.surname } }}
                style={{ marginRight: "8px" }}
                required
                InputProps={{
                  readOnly: true, // Makes the input field read-only
                }}
              />
              <TextField
                label="Given Name"
                variant="outlined"
                name="given_name"
                value={formData.given_name}
                onChange={handleChange}
                fullWidth
                slotProps={{ inputLabel: { shrink: !!formData.given_name } }}
                required
                InputProps={{
                  readOnly: true, // Makes the input field read-only
                }}
              />
            </Box>
            <Box display="flex" mb={2}>
              <TextField
                label="Nationality"
                variant="outlined"
                name="nationality"
                value={formData.nationality}
                onChange={handleChange}
                fullWidth
                slotProps={{ inputLabel: { shrink: !!formData.nationality } }}
                style={{ marginRight: "8px" }}
                required
                InputProps={{
                  readOnly: true, // Makes the input field read-only
                }}
              />
              <TextField
                label="Sex"
                variant="outlined"
                name="sex"
                value={formData.sex}
                onChange={handleChange}
                fullWidth
                required
                slotProps={{ inputLabel: { shrink: !!formData.sex } }}
                style={{ marginRight: "8px" }}
                InputProps={{
                  readOnly: true, // Makes the input field read-only
                }}
              />
              <TextField
                label="Date of Birth"
                type="date"
                variant="outlined"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
                fullWidth
                slotProps={{ shrink: true }}
                required
                InputProps={{
                  readOnly: true, // Makes the input field read-only
                }}
              />
            </Box>

            <Box display="flex" mb={2}>
              <TextField
                label="Phone number"
                variant="outlined"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                fullWidth
                slotProps={{
                  inputLabel: { shrink: !!formData.phone_number },
                }}
                style={{ marginRight: "8px" }}
              />
              <TextField
                label="Wapp Number"
                variant="outlined"
                name="wapp_number"
                value={formData.wapp_number}
                onChange={handleChange}
                fullWidth
                slotProps={{ inputLabel: { shrink: !!formData.wapp_number } }}
                style={{ marginRight: "8px" }}
              />
              <TextField
                label="Emergency Contact"
                variant="outlined"
                name="emergency_number"
                slotProps={{
                  inputLabel: { shrink: !!formData.emergency_number },
                }}
                value={formData.emergency_number}
                onChange={handleChange}
                fullWidth
              />
            </Box>
          </Box>

          {/* Passport Details Section */}
          <Box mb={4}>
            <Typography variant="h6" gutterBottom>
              Passport Details
            </Typography>
            <Divider />
            <Box display="flex" mt={2} mb={2}>
              <TextField
                label="Passport Number"
                variant="outlined"
                name="passport_number"
                value={formData.passport_number}
                onChange={handleChange}
                fullWidth
                slotProps={{
                  inputLabel: { shrink: !!formData.passport_number },
                }}
                style={{ marginRight: "8px" }}
                required
              />
              <TextField
                label="Place of Birth"
                variant="outlined"
                name="place_of_birth"
                value={formData.place_of_birth}
                onChange={handleChange}
                fullWidth
                slotProps={{
                  inputLabel: { shrink: !!formData.place_of_birth },
                }}
                style={{ marginRight: "8px" }}
              />
              <TextField
                label="Place of Issue"
                variant="outlined"
                name="place_of_issue"
                value={formData.place_of_issue}
                slotProps={{
                  inputLabel: { shrink: !!formData.place_of_issue },
                }}
                onChange={handleChange}
                fullWidth
              />
            </Box>
            <Box display="flex" mb={2}>
              <TextField
                label="Issue Date"
                type="date"
                variant="outlined"
                name="passport_issue_date"
                value={formData.passport_issue_date}
                onChange={handleChange}
                fullWidth
                slotProps={{ shrink: true }}
                style={{ marginRight: "8px" }}
              />
              <TextField
                label="Expiry Date"
                type="date"
                variant="outlined"
                name="passport_expiry_date"
                value={formData.passport_expiry_date}
                onChange={handleChange}
                fullWidth
                slotProps={{ shrink: true }}
              />
            </Box>
          </Box>

          {/* ID Details Section */}
          <Box mb={4}>
            <Typography variant="h6" gutterBottom>
              ID Details
            </Typography>
            <Divider />
            <Box display="flex" mt={2} mb={2}>
              <TextField
                label="ID Number"
                variant="outlined"
                name="id_number"
                value={formData.id_number}
                onChange={handleChange}
                fullWidth
                slotProps={{ inputLabel: { shrink: !!formData.id_number } }}
                style={{ marginRight: "8px" }}
              />
              <TextField
                label="ID Issue Date"
                type="date"
                variant="outlined"
                name="id_issue_date"
                value={formData.id_issue_date}
                onChange={handleChange}
                fullWidth
                slotProps={{ shrink: true }}
                style={{ marginRight: "8px" }}
              />
              <TextField
                label="ID Expiry Date"
                type="date"
                variant="outlined"
                name="id_expiry_date"
                value={formData.id_expiry_date}
                onChange={handleChange}
                fullWidth
                slotProps={{ shrink: true }}
              />
            </Box>
          </Box>

          {/* Company Details Section */}
          <Box mb={4}>
            <Typography variant="h6" gutterBottom>
              Company Details
            </Typography>
            <Divider />
            <Box display="flex" mt={2}>
              <TextField
                label="Company Name"
                variant="outlined"
                name="company_name"
                value={formData.company_name}
                onChange={handleChange}
                fullWidth
                slotProps={{
                  inputLabel: { shrink: !!formData.company_name },
                }}
                style={{ marginRight: "8px" }}
              />

              <TextField
                label="Position"
                variant="outlined"
                name="position"
                value={formData.position}
                slotProps={{ inputLabel: { shrink: !!formData.position } }}
                onChange={handleChange}
                fullWidth
              />
            </Box>
          </Box>

          {/* Bank Details */}
          <Box mb={4}>
            <Typography variant="h6" gutterBottom>
              Bank Details
            </Typography>
            <Divider />
            <Box display="flex" mt={2} mb={2}>
              <TextField
                label="Bank Name"
                variant="outlined"
                name="bank_name"
                value={formData.bank_name}
                onChange={handleChange}
                fullWidth
                slotProps={{ inputLabel: { shrink: !!formData.bank_name } }}
                style={{ marginRight: "8px" }}
              />
              <TextField
                label="Acc Number"
                variant="outlined"
                name="acc_number"
                value={formData.acc_number}
                slotProps={{ inputLabel: { shrink: !!formData.acc_number } }}
                onChange={handleChange}
                fullWidth
              />
            </Box>
            <Box display="flex" mb={2}>
              <TextField
                label="Card Number"
                variant="outlined"
                name="card_number"
                value={formData.card_number}
                slotProps={{ inputLabel: { shrink: !!formData.card_number } }}
                onChange={handleChange}
                fullWidth
                style={{ marginRight: "8px" }}
              />

              <TextField
                label="Expiry"
                type="date"
                variant="outlined"
                name="card_expiry"
                value={formData.card_expiry}
                onChange={handleChange}
                fullWidth
                slotProps={{ inputLabel: { shrink: true } }}
                style={{ marginRight: "8px" }}
              />

              <TextField
                label="CVV"
                variant="outlined"
                name="cvv"
                value={formData.cvv}
                onChange={handleChange}
                slotProps={{ inputLabel: { shrink: !!formData.cvv } }}
                fullWidth
              />
            </Box>
            <Box display="flex" mb={2}>
              <TextField
                label="Pin"
                variant="outlined"
                name="pin"
                value={formData.pin}
                onChange={handleChange}
                slotProps={{ inputLabel: { shrink: !!formData.pin } }}
                fullWidth
                style={{ marginRight: "8px" }}
              />
              <TextField
                label="Branch"
                variant="outlined"
                name="branch"
                value={formData.branch}
                onChange={handleChange}
                slotProps={{ inputLabel: { shrink: !!formData.branch } }}
                fullWidth
                style={{ marginRight: "8px" }}
              />
              <TextField
                label="remarks"
                variant="outlined"
                name="remarks"
                value={formData.remarks}
                slotProps={{ inputLabel: { shrink: !!formData.remarks } }}
                onChange={handleChange}
                fullWidth
              />
            </Box>
          </Box>

          {/* Buttons */}
          <Box mt={4} display="flex" justifyContent="space-between">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ flex: 1, marginRight: "8px" }}
            >
              Update changes
            </Button>
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              onClick={handleBack}
              style={{ flex: 1 }}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Container>
    </Layout>
  );
};

export default EditEmp;
