import React, { useState, useEffect, useMemo } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  TableSortLabel,
  Fab,
  Checkbox,
  MenuItem,
  Popper,
  ClickAwayListener,
  Paper,
  Grow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";
import Icons from "../../utils/icon";
import config from "../../config";
import Layout from "../../utils/Layout";
import { formatDate } from "../../utils/dateUtil";
// import LoadingPage from "../../utils/loading";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useToast } from "../../utils/ToastContext";
import CircularProgress from "@mui/material/CircularProgress";

const EmployeeTable = () => {
  const [employees, setEmployees] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [filters, setFilters] = useState({
    status: ["A", "D"],
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [statusUpdate, setStatusUpdate] = useState(null);
  // const [openDialog, setOpenDialog] = useState(false);
  // const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const triggerToast = useToast();
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    employeeId: null,
  });
  const [confirmationInput, setConfirmationInput] = useState("");
  const [inputError, setInputError] = useState(false);

  useEffect(() => {
    const fetchEmployees = async () => {
      const username = localStorage.getItem("username");
      const session_id = localStorage.getItem("session_id");

      if (username && session_id) {
        try {
          setLoading(true);
          const response = await fetch(
            `${config.apiBaseUrl}/employee/get_all_emp.php`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                username,
                session_id,
                search: searchQuery || null,
              }),
            }
          );

          const result = await response.json();
          if (result.success) {
            setEmployees(result.data);
          } else {
            console.error("Error fetching employees:", result.message);
          }
        } catch (error) {
          console.error("Error fetching employees:", error);
        } finally {
          setLoading(false);
        }
      } else {
        navigate("/");
      }
    };

    fetchEmployees();
  }, [searchQuery]);

  const handleCheckboxChange = (id) => {
    setSelectedEmployees((prev) =>
      prev.includes(id) ? prev.filter((empId) => empId !== id) : [...prev, id]
    );
  };

  const handleExportPDF = () => {
    const doc = new jsPDF();
    const tableData = selectedEmployees.map((id) => {
      const employee = employees.find((emp) => emp.id === id);
      return [
        employee.given_name + " " + employee.surname,
        employee.dob,
        employee.id_number,
        employee.id_expiry_date,
        employee.passport_expiry_date,
      ];
    });

    doc.text("Employee Report", 14, 10);
    doc.autoTable({
      head: [
        ["Name", "Date of Birth", "ID Number", "ID Expiry", "Passport Expiry"],
      ],
      body: tableData,
    });

    doc.save("Employee_List.pdf");
  };

  const handleFilterClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleFilterChange = (event, filterType) => {
    const { value, checked } = event.target;
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      // Add or remove the value from the filter
      if (checked) {
        updatedFilters[filterType] = [...updatedFilters[filterType], value];
      } else {
        updatedFilters[filterType] = updatedFilters[filterType].filter(
          (item) => item !== value
        );
      }

      return updatedFilters;
    });
  };

  const filteredEmployees = useMemo(() => {
    return employees.filter((employee) => {
      const { status } = filters;
      return status.includes(employee.status); // Filter by status
    });
  }, [employees, filters]);

  const handleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  const sortedEmployees = useMemo(() => {
    const employeesToSort = filteredEmployees; // Use filtered employees here

    return employeesToSort.sort((a, b) => {
      const aValue = a[sortConfig.key] || "";
      const bValue = b[sortConfig.key] || "";

      // String comparison logic (for text columns)
      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortConfig.direction === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      // Handle number or other types (like date) comparison
      if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
  }, [filteredEmployees, sortConfig]); // Ensure this depends on both filteredEmployees and sortConfig

  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: "4px",
          },
        },
      },
    },
  });

  const toggleStatus = async (employeeId) => {
    const username = localStorage.getItem("username");
    const session_id = localStorage.getItem("session_id");

    if (username && session_id) {
      try {
        const response = await fetch(
          `${config.apiBaseUrl}/employee/change_status_emp.php`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              username,
              session_id,
              id: employeeId,
            }),
          }
        );

        const result = await response.json();

        if (result.success) {
          // Log success message and new status
          const newStatus = result.message.match(/to '(\w)'/)[1]; // Extract new status (e.g., 'A')
          // triggerToast("success", `Employee status changed to ${newStatus}`);
          // Update status in the state
          setStatusUpdate({
            employeeId,
            newStatus,
          });
        } else {
          console.error("Error updating status:", result.message);
        }
      } catch (error) {
        console.error("Error updating status:", error);
      }
    }
  };

  useEffect(() => {
    if (statusUpdate) {
      setEmployees((prevEmployees) =>
        prevEmployees.map((employee) =>
          employee.id === statusUpdate.employeeId
            ? { ...employee, status: statusUpdate.newStatus }
            : employee
        )
      );
      // Reset statusUpdate to avoid unnecessary updates
      setStatusUpdate(null);
    }
  }, [statusUpdate]);

  const handleConfirm = (employeeId, currentStatus) => {
    const action = currentStatus === "A" ? "disable" : "enable";
    setConfirmDialog({
      open: true,
      employeeId,
      action,
    });
    setConfirmationInput(""); // Reset input field
    setInputError(false); // Reset error state
  };

  const handleProceed = async () => {
    if (
      confirmationInput.toLowerCase() !== confirmDialog.action.toLowerCase()
    ) {
      setInputError(true);
      return;
    }

    setLoading(true);
    setInputError(false);

    try {
      // Simulate the async operation
      await toggleStatus(confirmDialog.employeeId);

      // Display appropriate toast based on action
      if (confirmDialog.action === "disable") {
        triggerToast(
          "warning",
          `Employee successfully ${confirmDialog.action}d.`
        );
      } else if (confirmDialog.action === "enable") {
        triggerToast(
          "success",
          `Employee successfully ${confirmDialog.action}d.`
        );
      }

      // Close the dialog after successful operation
      setConfirmDialog({ open: false, action: null, employeeId: null });
    } catch (error) {
      console.error("Error performing action:", error);
      triggerToast("error", "Failed to update employee status.");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setConfirmDialog({
      open: false,
      employeeId: null,
      action: "",
    });
    setInputError(false);
  };

  const cellStyles = {
    border: "none",
    boxShadow: "0px 0px 0px 0.5px rgba(127, 147, 184, 0.2)",
  };

  const cellCenter = {
    border: "none",
    boxShadow: "0px 0px 0px 0.5px rgba(127, 147, 184, 0.2)",
    textAlign: "center",
    verticalAlign: "middle",
  };

  const getDateColor = (date) => {
    if (!date || date === "0000-00-00") {
      return { color: "grey", backgroundColor: "transparent" }; // Default style for invalid dates
    }

    const today = new Date();
    const parsedDate = new Date(date.replace(/-/g, "/"));

    if (isNaN(parsedDate)) {
      return { color: "grey", backgroundColor: "transparent" }; // Fallback for invalid date
    }

    const diffInDays = Math.ceil((parsedDate - today) / (1000 * 60 * 60 * 24));

    if (diffInDays <= 0) {
      return { color: "white", backgroundColor: "red" }; // Expired or today
    } else if (diffInDays <= 7) {
      return { color: "black", backgroundColor: "orange" }; // Within a week
    } else if (diffInDays <= 30) {
      return { color: "black", backgroundColor: "yellow" }; // Within a month
    } else if (diffInDays <= 90) {
      return { color: "black", backgroundColor: "lightgreen" }; // Within 3 months
    }

    return { color: "grey", backgroundColor: "transparent" }; // Default for dates beyond a month
  };

  const getStatusLabel = (statusV) => {
    switch (statusV) {
      case "A":
        return "Active";
      case "D":
        return "Inactive";
      default:
        return "N/A";
    }
  };

  const getStatusBackgroundColor = (statusValue) => {
    if (statusValue === "A") {
      return "#4CAF50";
    }
    if (statusValue === "D") {
      return "#FF5252";
    }
    return "transparent";
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          height: "90vh",
          maxWidth: "md",
          margin: "auto",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            position: "sticky",
            top: 0,
            zIndex: 2,
            backgroundColor: "white",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            display: "flex",
            justifyContent: "Center",
            alignItems: "center",
            paddingRight: 20,
            paddingLeft: 20,
            paddingBottom: 1,
            gap: 2,
          }}
        >
          <Fab
            color="primary"
            aria-label="add"
            onClick={handleExportPDF}
            disabled={selectedEmployees.length === 0}
            sx={{
              borderRadius: 1,
              width: 35,
              height: 35,
              minHeight: "unset",
            }}
          >
            <Icons.Download />
          </Fab>
          <TextField
            variant="outlined"
            placeholder="Search Employee..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            size="small"
            sx={{ flexGrow: 0.5, marginLeft: 1 }}
          />
          <Fab
            color="secondary"
            aria-label="filter"
            onClick={handleFilterClick}
            sx={{
              marginRight: 1,
              borderRadius: 1,
              width: 35,
              height: 35,
              minHeight: "unset",
              zIndex: 200,
            }}
          >
            <Icons.FilterListIcon />
          </Fab>
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => navigate("/add_emp")}
            sx={{
              borderRadius: 1,
              width: 35,
              height: 35,
              minHeight: "unset",
            }}
          >
            <Icons.Add />
          </Fab>
        </Box>

        <Popper
          open={open}
          anchorEl={anchorEl}
          transition
          placement="bottom"
          modifiers={[
            { name: "preventOverflow", options: { boundary: "viewport" } },
            {
              name: "offset",
              options: {
                offset: [0, 5], // [horizontal offset, vertical offset]
              },
            },
          ]}
          sx={{
            zIndex: 150, // Ensures the Popper is above most elements
          }}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <Box sx={{ padding: 2 }}>
                    {/* <Typography variant="subtitle1">Filters</Typography> */}
                    <Box sx={{ marginBottom: 1 }}>
                      <Typography>Status</Typography>
                      {["A", "D"].map((value) => (
                        <MenuItem key={value}>
                          <Checkbox
                            checked={filters.status.includes(value)}
                            onChange={(e) => handleFilterChange(e, "status")}
                            value={value}
                          />
                          {value}
                        </MenuItem>
                      ))}
                    </Box>
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

        {/* Table Container */}
        <ThemeProvider theme={theme}>
          <TableContainer
            sx={{
              flexGrow: 1,
              height: "calc(100vh - 90px)",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "5px",
                height: "5px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              },
            }}
          >
            <Table
              stickyHeader
              sx={{
                tableLayout: "auto",
                width: "md",
              }}
            >
              {" "}
              <TableHead>
                <TableRow
                  sx={{
                    bgcolor: "primary.main",
                    color: "white",
                    zIndex: 1000,
                  }}
                >
                  {" "}
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selectedEmployees.length > 0 &&
                        selectedEmployees.length < sortedEmployees.length
                      }
                      checked={
                        selectedEmployees.length === sortedEmployees.length &&
                        sortedEmployees.length > 0
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedEmployees(
                            sortedEmployees.map((employee) => employee.id)
                          ); // Select all
                        } else {
                          setSelectedEmployees([]); // Deselect all
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === "given_name"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("given_name")}
                    >
                      Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    {" "}
                    <TableSortLabel
                      active={sortConfig.key === "id_expiry_date"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("id_expiry_date")}
                    >
                      Resident ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    {" "}
                    <TableSortLabel
                      active={sortConfig.key === "passport_expiry_date"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("passport_expiry_date")}
                    >
                      Passport
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>
                    {" "}
                    <TableSortLabel
                      active={sortConfig.key === "status"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("status")}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <Icons.EditIcon />
                  </TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <div className="loading-container">
                  <div className="spinner"></div>
                  <p>Loading, please wait...</p>
                </div>
              ) : (
                <TableBody>
                  {sortedEmployees.map((employee) => (
                    <TableRow
                      key={employee.id}
                      sx={{
                        "&:nth-of-type(odd)": { bgcolor: "grey.200" }, // Alternate row colors
                      }}
                    >
                      <TableCell>
                        <Checkbox
                          checked={selectedEmployees.includes(employee.id)}
                          onChange={() => handleCheckboxChange(employee.id)}
                        />
                      </TableCell>
                      <TableCell
                        sx={cellStyles}
                        onClick={() => navigate(`/emp_details/${employee.id}`)}
                      >
                        <Box
                          sx={{
                            cursor: "pointer",
                            color: "blue", // Link color
                            textDecoration: "underline", // Underline the text
                            "&:hover": {
                              color: "darkblue", // Darker color on hover
                              textDecoration: "none", // Remove underline on hover
                            },
                          }}
                        >
                          {employee.given_name} {employee.surname}
                        </Box>
                      </TableCell>

                      <TableCell
                        sx={{
                          ...cellStyles,
                          textAlign: "center", // Center-align content
                          verticalAlign: "middle", // Ensure vertical centering
                        }}
                      >
                        <Box>
                          {/* First line: ID number */}
                          <Box component="span">{employee.id_number}</Box>

                          {/* Second line: Expiry date */}
                          <Box
                            component="span"
                            sx={{
                              display: "block", // New line for expiry date
                              fontSize: "0.9rem", // Smaller font
                              fontStyle: "italic", // Italic text
                              marginLeft: "10px",
                              marginRight: "10px",
                              padding: "2px 4px", // Add padding for better visibility
                              borderRadius: "4px", // Rounded corners for the background
                              color: getDateColor(employee.id_expiry_date), // Dynamic color based on date
                              whiteSpace: "nowrap", // Prevent wrapping of text
                              overflow: "hidden", // Hide overflow content
                              textOverflow: "ellipsis", // Add ellipsis for overflow text
                            }}
                          >
                            {formatDate(employee.id_expiry_date)}
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell
                        sx={{
                          ...cellStyles,
                          textAlign: "center", // Center-align content
                          verticalAlign: "middle", // Ensure vertical centering
                        }}
                      >
                        <Box>
                          {/* First line: ID number */}
                          <Box component="span">{employee.passport_number}</Box>

                          {/* Second line: Expiry date */}
                          <Box
                            component="span"
                            sx={{
                              display: "block", // New line for expiry date
                              fontSize: "0.9rem", // Smaller font
                              fontStyle: "italic", // Italic text
                              marginLeft: "10px",
                              marginRight: "10px",
                              padding: "2px 4px", // Add padding for better visibility
                              borderRadius: "4px", // Rounded corners for the background
                              color: getDateColor(
                                employee.passport_expiry_date
                              ), // Dynamic color based on date
                              whiteSpace: "nowrap", // Prevent wrapping of text
                              overflow: "hidden", // Hide overflow content
                              textOverflow: "ellipsis", // Add ellipsis for overflow text
                            }}
                          >
                            {formatDate(employee.passport_expiry_date)}
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell sx={cellCenter}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {/* First line: Phone number */}
                          <Box component="span">{employee.phone_number}</Box>

                          {/* Conditionally render second line for Wapp number */}
                          {employee.wapp_number && (
                            <Box
                              component="span"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "0.9rem", // Smaller font
                                fontStyle: "italic", // Italic text
                                color: "darkgrey", // Dark grey color
                                marginTop: "4px", // Space between phone and wapp number
                              }}
                            >
                              {/* WhatsApp Icon */}
                              <Icons.WhatsAppIcon
                                sx={{
                                  fontSize: "1.2rem", // Adjust the size of the icon
                                  marginRight: "4px", // Space between icon and number
                                  color: "green", // WhatsApp icon color
                                }}
                              />
                              {/* Wapp number as a clickable link */}
                              <a
                                href={`https://wa.me/${employee.wapp_number}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: "darkgrey",
                                  textDecoration: "none",
                                }}
                              >
                                {employee.wapp_number}
                              </a>
                            </Box>
                          )}
                        </Box>
                      </TableCell>

                      <TableCell sx={cellCenter}>
                        <Typography variant="subtitle1">
                          <Box
                            component="span"
                            onClick={() =>
                              handleConfirm(employee.id, employee.status)
                            } // Pass current status
                            sx={{
                              backgroundColor: getStatusBackgroundColor(
                                employee.status
                              ),
                              color: "#fff",
                              padding: "4px 8px",
                              borderRadius: "4px",
                              cursor: "pointer",
                              "&:hover": {
                                opacity: 0.8,
                              },
                            }}
                          >
                            {getStatusLabel(employee.status)}{" "}
                            {/* Display the status label */}
                          </Box>
                        </Typography>
                      </TableCell>
                      <TableCell sx={cellStyles}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row", // Ensure horizontal alignment
                            alignItems: "center", // Align items vertically in the center
                            gap: "8px", // Add spacing between buttons
                            flexWrap: "nowrap", // Prevent wrapping to the next line
                          }}
                        >
                          <Fab
                            color="primary"
                            aria-label="edit"
                            onClick={() => navigate(`/edit_emp/${employee.id}`)}
                            sx={{
                              width: 35,
                              height: 35,
                              borderRadius: "4px",
                              minHeight: "unset",
                              minWidth: "unset",
                              zIndex: 1,
                            }}
                          >
                            <Icons.EditNoteIcon sx={{ fontSize: 20 }} />
                          </Fab>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </ThemeProvider>
      </Box>
      <Dialog
        open={confirmDialog.open}
        onClose={!loading ? handleCancel : null}
      >
        <DialogTitle>
          Confirm{" "}
          {confirmDialog.action
            ? confirmDialog.action.charAt(0).toUpperCase() +
              confirmDialog.action.slice(1)
            : ""}
        </DialogTitle>

        <DialogContent>
          <Typography>
            Are you sure you want to {confirmDialog.action} this employee?
            Please type <strong>{confirmDialog.action}</strong> to confirm.
          </Typography>
          <TextField
            fullWidth
            value={confirmationInput}
            onChange={(e) => setConfirmationInput(e.target.value)}
            error={inputError}
            helperText={
              inputError
                ? `Please type "${confirmDialog.action}" to proceed.`
                : ""
            }
            margin="normal"
            onKeyDown={(e) => {
              if (e.key === "Enter" && !loading) {
                handleProceed();
              }
            }}
            disabled={loading}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="secondary" disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={handleProceed}
            color="primary"
            disabled={!confirmationInput || loading}
            startIcon={loading && <CircularProgress size={20} />}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default EmployeeTable;
