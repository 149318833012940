import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Container,
  Divider,
  IconButton,
  MenuItem,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useToast } from "../../utils/ToastContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Layout from "../../utils/Layout";
import config from "../../config";

const EditTransaction = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [siteList, setSiteList] = useState([]); // To store site names
  const triggerToast = useToast();

  const [formData, setFormData] = useState({
    id: id,
    site_name: "",
    amount: "",
    remarks: "",
    date: "",
    username: localStorage.username,
    session_id: localStorage.session_id,
  });

  // Fetch site names from the API
  useEffect(() => {
    const fetchSiteNames = async () => {
      try {
        const response = await axios.post(
          `${config.apiBaseUrl}/site/get_site_list.php`,
          {
            username: localStorage.username,
            session_id: localStorage.session_id,
          },
          {
            headers: {
              "Content-Type": " application/json",
            },
          }
        );

        if (response.data.success) {
          setSiteList(response.data.data); // Assuming the data is an array of { id, surname }
        } else {
          triggerToast("error", response.data.message); // Show error message
        }
      } catch (error) {
        console.error("Error fetching site names:", error);
        triggerToast("error", "Failed to load site names.");
      }
    };

    fetchSiteNames();
  }, []);

  // Fetch existing transaction data for editing
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const requestData = {
          id,
          username: localStorage.getItem("username"),
          session_id: localStorage.getItem("session_id"),
        };

        const response = await axios.post(
          `${config.apiBaseUrl}/transaction/get_by_id_transaction.php`,
          requestData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success) {
          // triggerToast("success", "Transaction Updated successfully!");
          const fetchedData = response.data.transaction;
          setFormData((prevData) => ({ ...prevData, ...fetchedData }));
        } else {
          triggerToast(
            "error",
            response.data.message || "Failed to fetch transaction data."
          );
        }
      } catch (error) {
        console.error("Error fetching transaction data:", error);
        triggerToast(
          "error",
          "An error occurred while fetching transaction data."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const updatedFormData = {
        ...formData,
        id: id,
        username: localStorage.username,
        session_id: localStorage.session_id,
      };

      const response = await axios.post(
        `${config.apiBaseUrl}/transaction/update_transaction.php`,
        updatedFormData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        triggerToast("success", response.data.message);
        navigate("/transaction");

        // setTimeout(() => {
        // }, 2000);
      } else {
        triggerToast("error", response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      triggerToast("error", "Something went wrong!");
    }
  };

  const handleBack = () => {
    navigate("/transaction");
  };

  return (
    <Layout>
      <Container
        maxWidth="md"
        style={{
          overflow: "auto",
          maxHeight: "90vh",
          marginBottom: 10,
          scrollbarWidth: "thin",
          scrollbarColor: "lightgrey transparent",
          padding: "16px",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#ffffff",
        }}
      >
        <Box display="flex" alignItems="center" mb={3}>
          <IconButton onClick={handleBack}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h5" ml={1}>
            Edit Transaction
          </Typography>
        </Box>

        <form onSubmit={handleSubmit}>
          <Box mb={4}>
            <Typography variant="h6" gutterBottom>
              Transaction Details
            </Typography>
            <Divider />
            <Box display="flex" flexDirection="column" mt={2} mb={2}>
              {/* Site Name Dropdown */}
              <TextField
                select
                label="Site Name"
                variant="outlined"
                name="site_name"
                value={formData.site_name}
                onChange={handleChange}
                required
                fullWidth
                style={{ marginBottom: "8px" }}
              >
                {siteList.map((site) => (
                  <MenuItem key={site.id} value={site.id}>
                    {site.site_name}
                  </MenuItem>
                ))}
              </TextField>

              {/* Amount Field */}
              <TextField
                label="Amount"
                variant="outlined"
                name="amount"
                type="number"
                value={formData.amount}
                onChange={handleChange}
                required
                fullWidth
                style={{ marginBottom: "8px" }}
              />

              {/* Date Field */}
              <TextField
                label="Date"
                type="date"
                variant="outlined"
                name="date"
                value={formData.date}
                onChange={handleChange}
                required
                fullWidth
                style={{ marginBottom: "8px" }}
                InputLabelProps={{ shrink: true }}
              />

              {/* Remarks Field */}
              <TextField
                label="Remarks"
                variant="outlined"
                name="remarks"
                value={formData.remarks}
                onChange={handleChange}
                multiline
                rows={4}
                fullWidth
              />
            </Box>
          </Box>

          {/* Buttons */}
          <Box mt={4} display="flex" justifyContent="space-between">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ flex: 1, marginRight: "8px" }}
            >
              Update Changes
            </Button>
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              onClick={handleBack}
              style={{ flex: 1 }}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Container>
    </Layout>
  );
};

export default EditTransaction;
