import React, { createContext, useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);
export const ToastProvider = ({ children }) => {
    console.log("ToastProvider initialized"); // Debug here
    const triggerToast = (type, message) => {
      console.log("triggerToast called:", type, message); // Debug here
      switch (type) {
        case "success":
          toast.success(message);
          break;
        case "error":
          toast.error(message);
          break;
        case "info":
          toast.info(message);
          break;
        case "warning":
          toast.warning(message);
          break;
      }
    };
  
    return (
      <ToastContext.Provider value={triggerToast}>
        {children}
        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ToastContext.Provider>
    );
  };
  