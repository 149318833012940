import React, { useState, useEffect, useMemo } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  TableSortLabel,
  Fab,
  Checkbox,
  InputAdornment,
  MenuItem,
  Popper,
  ClickAwayListener,
  Paper,
  Grow,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Layout from "../../utils/Layout";
import config from "../../config";
import Icons from "../../utils/icon";

import Tooltip from "@mui/material/Tooltip";

import LoadingPage from "../../utils/loading";

const Quotes = () => {
  const [quotes, setQuotes] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "desc",
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [selectedQuotes, setSelectedQuotes] = useState([]);
  const [filters, setFilters] = useState({
    status: ["Pending", "Approved", "Declined"],
    job: [
      "Waiting LPO",
      "Lpo Received",
      "Hold",
      "Advance received",
      "Work started",
      "Work completed",
      "Closed",
    ],
    selectedStatus: [],
    selectedJob: [],
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleFilterClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (filterType, selectedValues) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [`selected${filterType}`]: selectedValues,
    }));
  };

  useEffect(() => {
    const fetchQuotes = async () => {
      const username = localStorage.getItem("username");
      const session_id = localStorage.getItem("session_id");

      if (username && session_id) {
        try {
          setLoading(true); // Set loading to true before starting the fetch
          const response = await fetch(
            `${config.apiBaseUrl}/get_all_quote.php`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                username,
                session_id,
                search: searchQuery || null,
              }),
            }
          );

          const result = await response.json();
          console.log("Response from PHP:", result);

          if (result.success) {
            setQuotes(result.data);
          } else {
            console.error("Error fetching quotes:", result.message);
          }
        } catch (error) {
          console.error("Error fetching quotes:", error);
        } finally {
          setLoading(false); // Ensure loading is set to false regardless of success or failure
        }
      } else {
        navigate("/");
      }
    };

    fetchQuotes();
  }, [navigate, searchQuery]);

  const filteredQuotes = quotes; // Removed all filter conditions

  const handleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      order: prev.key === key && prev.order === "asc" ? "desc" : "asc", // Toggle order
    }));
  };

  const sortedQuotes = useMemo(() => {
    return [...quotes].sort((a, b) => {
      const aValue = a[sortConfig.key] || "";
      const bValue = b[sortConfig.key] || "";

      if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
  }, [quotes, sortConfig]);

  const calculateReceivedAmount = (quote) => {
    const paymentTypes = ["advance_amount", "pay1", "pay2", "pay3"];
    const totalReceived = paymentTypes.reduce(
      (sum, paymentType) => sum + (parseFloat(quote[paymentType]) || 0),
      0
    );

    return isNaN(totalReceived) ? 0 : totalReceived;
  };

  const calculateDueDate = (completionDate) => {
    // Check if completionDate is null or '0000-00-00'
    if (!completionDate || completionDate === "0000-00-00") {
      return null; // Return null or an empty value
    }

    const dueDate = new Date(completionDate);
    dueDate.setDate(dueDate.getDate() + 90); // Add 90 days to the completion date

    const now = new Date();
    const differenceInDays = Math.ceil((dueDate - now) / (1000 * 60 * 60 * 24));

    if (differenceInDays <= 7 && differenceInDays > 0) {
      return (
        <span style={{ color: "orange" }}>{dueDate.toLocaleDateString()}</span>
      );
    } else if (differenceInDays <= 0) {
      return (
        <span style={{ color: "red" }}>{dueDate.toLocaleDateString()}</span>
      );
    } else {
      return <span>{dueDate.toLocaleDateString()}</span>;
    }
  };

  const getBalanceOrPaid = (quote) => {
    const calculateReceivedAmountSafe = (quote) => {
      const paymentTypes = ["advance_amount", "pay1", "pay2", "pay3"];
      const totalReceived = paymentTypes.reduce(
        (sum, paymentType) => sum + (parseFloat(quote[paymentType]) || 0),
        0
      );

      return isNaN(totalReceived) ? 0 : totalReceived;
    };

    const approvedAmount = parseFloat(quote.approved_amount);
    const hasApprovedAmount = !isNaN(approvedAmount) && approvedAmount > 0;
    const balance = hasApprovedAmount
      ? approvedAmount - calculateReceivedAmountSafe(quote)
      : 0;

    if (hasApprovedAmount) {
      if (balance === 0) {
        return (
          <div style={{ textAlign: "center" }}>
            <span style={{ color: "green" }}>Paid</span>
          </div>
        );
      } else {
        return (
          <div style={{ textAlign: "center" }}>
            <div style={{ color: "text.secondary" }}>
              {balance.toFixed(2)} {config.currency}
            </div>
            {quote.date_of_completion &&
              quote.date_of_completion !== "0000-00-00" && (
                <div
                  style={{
                    fontSize: "0.9rem",
                    fontStyle: "italic",
                    color: "grey",
                    margin: 0,
                    whiteSpace: "nowrap",
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                >
                  Due: {calculateDueDate(quote.date_of_completion)}
                </div>
              )}
          </div>
        );
      }
    } else {
      return (
        <div style={{ textAlign: "center" }}>
          <span style={{ color: "red" }}></span>
        </div>
      );
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "Pending":
        return {
          icon: <Icons.HourglassEmptyIcon />,
          color: "#FFE0B2", // Light orange
          tooltip: "Pending approval",
        };
      case "Approved":
        return {
          icon: <Icons.ApprovalIcon />,
          color: "#C8E6C9", // Light green
          tooltip: "Approved",
        };
      case "In Progress":
        return {
          icon: <Icons.RotateLeftIcon />,
          color: "#BBDEFB", // Light blue
          tooltip: "In progress",
        };
      case "Completed":
        return {
          icon: <Icons.DoneIcon />,
          color: "#B2DFDB", // Light teal
          tooltip: "Completed",
        };
      case "Hold":
        return {
          icon: <Icons.PauseCircleOutlineIcon />,
          color: "#E0E0E0", // Light grey
          tooltip: "On hold",
        };
      case "Declined":
        return {
          icon: <Icons.ThumbDownOffAltIcon />,
          color: "#FFCDD2", // Light red
          tooltip: "Declined",
        };
      case "Advance Received":
        return {
          icon: <Icons.RequestQuoteIcon />,
          color: "#BBDEFB", // Light blue
          tooltip: "Advance Received",
        };
      case "Work completed":
        return {
          icon: <Icons.DoneIcon />,
          color: "#BBDEFB", // Light blue
          tooltip: "Work completed",
        };
      case "Closed":
        return {
          icon: <Icons.DoneAllIcon />,
          color: "#C8E6C9", // Light green
          tooltip: "Closed",
        };
      case "Lpo Received":
        return {
          icon: <Icons.ReceiptIcon />,
          color: "#BBDEFB", // Light blue
          tooltip: "Lpo Received",
        };
      case "Waiting LPO":
        return {
          icon: <Icons.PendingActionsIcon />,
          color: "#BBDEFB", // Light blue
          tooltip: "Waiting LPO",
        };
      default:
        return { icon: null, color: "#F5F5F5", tooltip: "" }; // Default light grey
    }
  };

  const cellStyles = {
    border: "none",
    boxShadow: "0px 0px 0px 0.5px rgba(127, 147, 184, 0.2)",
  };

  const handleCheckboxChange = (id) => {
    setSelectedQuotes((prev) =>
      prev.includes(id)
        ? prev.filter((quoteId) => quoteId !== id)
        : [...prev, id]
    );
  };

  const handleExportPDF = () => {
    const doc = new jsPDF();
    const filteredQuotes = quotes.filter((quote) =>
      selectedQuotes.includes(quote.id)
    );

    // Calculate table data
    const tableData = filteredQuotes.map((quote) => [
      quote.id,
      quote.quote_name,
      quote.quote_date,
      parseFloat(quote.approved_amount) || 0,
      calculateReceivedAmount(quote),
      quote.status,
    ]);

    // Calculate totals
    const totalAmount = filteredQuotes.reduce(
      (sum, quote) => sum + (parseFloat(quote.approved_amount) || 0),
      0
    );
    const totalReceived = filteredQuotes.reduce(
      (sum, quote) => sum + calculateReceivedAmount(quote),
      0
    );

    // Add totals row
    const totalsRow = [
      "",
      "Totals",
      "",
      totalAmount.toFixed(2),
      totalReceived.toFixed(2),
      "",
    ];
    tableData.push(totalsRow);

    // Generate the PDF
    doc.text("Quotes Report", 14, 10);
    doc.autoTable({
      head: [
        ["ID", "Quote Name", "Quote Date", "Amount", "Received", "Status"],
      ],
      body: tableData,
      footStyles: { fillColor: [0, 0, 0] }, // Optional: Style footer row
    });

    // Save the PDF
    doc.save("Quote_list.pdf");
  };

  const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: "4px",
          },
        },
      },
    },
  });

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          height: "90vh",
          maxWidth: "md",
          margin: "auto",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            position: "sticky",
            top: 0,
            zIndex: 2,
            backgroundColor: "white",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Fab
            color="primary"
            aria-label="add"
            onClick={handleExportPDF}
            disabled={selectedQuotes.length === 0}
            sx={{
              borderRadius: 1,
              width: 35,
              height: 35,
              minHeight: "unset",
            }}
          >
            <Icons.Download />
          </Fab>
          <TextField
            variant="outlined"
            placeholder="Search Quotes..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            size="small"
            sx={{ flexGrow: 1, marginLeft: 1 }}
          />
          <Fab
            color="secondary"
            aria-label="filter"
            onClick={handleFilterClick}
            style={{
              marginRight: 1,
              borderRadius: 4,
              width: 35,
              height: 35,
              minHeight: "unset",
              zIndex: 100,
            }}
          >
            <Icons.FilterListIcon />
          </Fab>
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => navigate("/add_quote")}
            sx={{
              borderRadius: 1,
              width: 35,
              height: 35,
              minHeight: "unset",
            }}
          >
            <Icons.Add />
          </Fab>
        </Box>

        <Popper
          open={open}
          anchorEl={anchorEl}
          transition
          placement="bottom-start"
          modifiers={[
            { name: "preventOverflow", options: { boundary: "viewport" } },
          ]}
          sx={{
            zIndex: 150, // Ensures the Popper is above most elements
          }}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <Box sx={{ padding: 2 }}>
                    <Typography variant="subtitle1">Status</Typography>
                    {filters.status.map((status) => (
                      <MenuItem key={status} value={status}>
                        <Checkbox
                          checked={filters.selectedStatus.includes(status)}
                          onChange={(e) =>
                            handleFilterChange(
                              "Status",
                              e.target.checked
                                ? [...filters.selectedStatus, status]
                                : filters.selectedStatus.filter(
                                    (s) => s !== status
                                  )
                            )
                          }
                        />
                        {status}
                      </MenuItem>
                    ))}
                    <Typography variant="subtitle1">Job</Typography>
                    {filters.job.map((job) => (
                      <MenuItem key={job} value={job}>
                        <Checkbox
                          checked={filters.selectedJob.includes(job)}
                          onChange={(e) =>
                            handleFilterChange(
                              "Job",
                              e.target.checked
                                ? [...filters.selectedJob, job]
                                : filters.selectedJob.filter((j) => j !== job)
                            )
                          }
                        />
                        {job}
                      </MenuItem>
                    ))}
                    <MenuItem
                      key="no_filter"
                      value="no_filter"
                      onClick={() => {
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          selectedStatus: [],
                          selectedJob: [],
                        }));
                        // handleClose();
                      }}
                    >
                      <Checkbox
                        checked={
                          filters.selectedStatus.length === 0 &&
                          filters.selectedJob.length === 0
                        }
                      />
                      No Filter
                    </MenuItem>
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

        {/* Table Container */}
        <ThemeProvider theme={theme}>
          <TableContainer
            sx={{
              flexGrow: 1,
              height: "calc(100vh - 90px)", // Fixed height minus the header height
              overflow: "auto", // Disable overflow
              "&::-webkit-scrollbar": {
                width: "5px",
                height: "5px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              },
            }}
          >
            <Table
              stickyHeader
              sx={{
                tableLayout: "auto",
                width: "md",
              }}
            >
              {" "}
              <TableHead>
                <TableRow
                  sx={{
                    bgcolor: "primary.main", // Using primary color from MUI palette
                    color: "white",
                    zIndex: 1000,
                  }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selectedQuotes.length > 0 &&
                        selectedQuotes.length < sortedQuotes.length
                      }
                      checked={
                        selectedQuotes.length === sortedQuotes.length &&
                        sortedQuotes.length > 0
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedQuotes(
                            sortedQuotes.map((quote) => quote.id)
                          ); // Select all
                        } else {
                          setSelectedQuotes([]); // Deselect all
                        }
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ width: "1%", whiteSpace: "nowrap" }}>
                    <TableSortLabel
                      active={sortConfig.key === "id"}
                      direction={
                        sortConfig.key === "id" ? sortConfig.direction : "asc"
                      }
                      onClick={() => handleSort("id")}
                    >
                      ID
                    </TableSortLabel>
                  </TableCell>

                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === "quote_name"}
                      direction={
                        sortConfig.key === "quote_name"
                          ? sortConfig.direction
                          : "asc"
                      }
                      onClick={() => handleSort("quote_name")}
                    >
                      Quote
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === "approved_amount"}
                      direction={
                        sortConfig.key === "approved_amount"
                          ? sortConfig.direction
                          : "asc"
                      }
                      onClick={() => handleSort("approved_amount")}
                    >
                      Amount
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === "balance"}
                      direction={
                        sortConfig.key === "balance"
                          ? sortConfig.direction
                          : "asc"
                      }
                      onClick={() => handleSort("balance")}
                    >
                      Balance
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ width: "1%", whiteSpace: "nowrap" }}>
                    <TableSortLabel
                      active={sortConfig.key === "status"}
                      direction={
                        sortConfig.key === "status"
                          ? sortConfig.direction
                          : "asc"
                      }
                      onClick={() => handleSort("status")}
                    >
                      Stat
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ width: "1%", whiteSpace: "nowrap" }}>
                    <TableSortLabel
                      active={sortConfig.key === "work_status"}
                      direction={
                        sortConfig.key === "work_status"
                          ? sortConfig.direction
                          : "asc"
                      }
                      onClick={() => handleSort("work_status")}
                    >
                      Job
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ width: "1%", whiteSpace: "nowrap" }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                  <LoadingPage />
                </Box>
              ) : (
                <TableBody>
                  {quotes.length > 0 ? (
                    quotes
                      .filter(
                        (q) =>
                          (filters.selectedStatus.length === 0 ||
                            filters.selectedStatus.includes(q.status)) &&
                          (filters.selectedJob.length === 0 ||
                            filters.selectedJob.includes(q.work_status))
                      )
                      .sort((a, b) => {
                        // Add your sorting logic here based on `handleSort`
                        if (sortConfig.key) {
                          const sortOrder = sortConfig.order === "asc" ? 1 : -1;
                          if (a[sortConfig.key] < b[sortConfig.key])
                            return -1 * sortOrder;
                          if (a[sortConfig.key] > b[sortConfig.key])
                            return 1 * sortOrder;
                        }
                        return 0; // If no sorting key is defined or values are equal
                      })
                      .map((quote) => (
                        <TableRow
                          key={quote.id}
                          sx={{
                            "&:nth-of-type(odd)": { bgcolor: "grey.200" }, // Alternate row colors
                          }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedQuotes.includes(quote.id)}
                              onChange={() => handleCheckboxChange(quote.id)}
                            />
                          </TableCell>

                          <TableCell sx={cellStyles}>{quote.id}</TableCell>

                          {/* name invoice and lpo number */}
                          <TableCell sx={cellStyles}>
                            <Typography
                              variant="body1"
                              component="div"
                              onClick={() =>
                                navigate(`/quote_details/${quote.id}`)
                              } // Navigate to details page with quote ID
                              sx={{
                                cursor: "pointer", // Change cursor to pointer
                                color: "primary.main", // Blue color for the link
                                "&:hover": {
                                  textDecoration: "underline", // Underline on hover
                                },
                                "@media (max-width: 600px)": {
                                  // Media query for mobile devices
                                  fontSize: "1rem", // Ensure font size scales down for mobile
                                },
                              }}
                            >
                              {quote.quote_name}
                            </Typography>
                            <Typography
                              variant="body2"
                              component="div"
                              sx={{
                                fontSize: "0.9rem",
                                fontStyle: "italic",
                                color: "text.secondary",
                                whiteSpace: "nowrap", // Prevent text from wrapping
                              }}
                            >
                              {[
                                quote.invoice_number &&
                                  `Invoice: ${quote.invoice_number}`,
                                quote.lpo_number && `LPO: ${quote.lpo_number}`,
                              ]
                                .filter(Boolean) // Remove null/undefined values
                                .join(" | ")}
                            </Typography>
                          </TableCell>

                          {/* approved or quote amount handling */}
                          <TableCell sx={cellStyles}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {quote.approved_amount ? (
                                <>
                                  {quote.approved_amount} {config.currency}
                                </>
                              ) : (
                                <>
                                  Q {quote.quoted_amount} {config.currency}
                                </>
                              )}
                              {quote.approved_amount && (
                                <div
                                  style={{
                                    fontSize: "0.9rem",
                                    fontStyle: "italic",
                                    color: "grey", // Apply the secondary color here
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Rec.: {calculateReceivedAmount(quote)}
                                </div>
                              )}
                            </div>
                          </TableCell>

                          {/* balance */}

                          <TableCell
                            sx={{
                              ...cellStyles,
                              position: "relative", // Create a stacking context
                            }}
                          >
                            {" "}
                            {getBalanceOrPaid(quote)}
                          </TableCell>
                          {/* status */}
                          <TableCell sx={cellStyles}>
                            {getStatusIcon(quote.status).icon && (
                              <Tooltip
                                title={getStatusIcon(quote.status).tooltip}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center", // Vertically centers the icon
                                    justifyContent: "center", // Horizontally centers the icon
                                  }}
                                >
                                  <Fab
                                    sx={{
                                      width: 35, // Consistent size for all Fab buttons
                                      height: 35,
                                      borderRadius: "4px",
                                      minHeight: "unset",
                                      minWidth: "unset",
                                      backgroundColor: getStatusIcon(
                                        quote.status
                                      ).color, // Use the light background color from getStatusIcon
                                      color: "inherit", // Ensures icon color matches theme
                                      cursor: "default", // Prevents clickable behavior
                                      zIndex: 1,
                                    }}
                                  >
                                    {getStatusIcon(quote.status).icon}
                                  </Fab>
                                </div>
                              </Tooltip>
                            )}
                          </TableCell>

                          {/* work progress */}
                          <TableCell
                            sx={{
                              ...cellStyles,
                              position: "relative", // Create a stacking context
                              zIndex: 1, // Ensure it's below the header
                            }}
                          >
                            {" "}
                            {getStatusIcon(quote.work_status).icon && (
                              <Tooltip
                                title={getStatusIcon(quote.work_status).tooltip}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Fab
                                    sx={{
                                      width: 35,
                                      height: 35,
                                      borderRadius: "4px",
                                      minHeight: "unset",
                                      minWidth: "unset",
                                      backgroundColor: getStatusIcon(
                                        quote.work_status
                                      ).color, // Use lighter background
                                      color: "inherit", // Icon color remains unchanged
                                      cursor: "default",
                                    }}
                                  >
                                    {getStatusIcon(quote.work_status).icon}
                                  </Fab>
                                </div>
                              </Tooltip>
                            )}
                          </TableCell>

                          {/* Action */}
                          <TableCell
                            sx={{
                              ...cellStyles,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              minHeight: "unset",
                              padding: 1.2,
                              position: "relative", // Ensure proper stacking context
                              zIndex: 1, // Lower than the header
                            }}
                          >
                            <Fab
                              color="primary"
                              aria-label="edit"
                              onClick={() =>
                                navigate(`/edit_quote/${quote.id}`)
                              }
                              sx={{
                                width: 35,
                                height: 35,
                                borderRadius: "4px",
                                minHeight: "unset",
                                minWidth: "unset",
                                zIndex: 1, // Lower than the header
                              }}
                            >
                              <Icons.EditNoteIcon sx={{ fontSize: 20 }} />
                            </Fab>
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        No quotes available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </ThemeProvider>
      </Box>
    </Layout>
  );
};

export default Quotes;
