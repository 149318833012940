import React from "react";
import { Card, CardActionArea, Box, Typography, Grid2 } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Layout from "../utils/Layout";
import routes from "../routes";

const Dashboard = () => {
  const navigate = useNavigate();

  // Filter routes to include only those marked as showInDashboard
  const mainPages = routes.filter((route) => route.showInDashboard);

  return (
    <Layout>
      <Box
        sx={{
          padding: 2,
          backgroundColor: "#f5f5f5",
          minHeight: "100vh",
          overflowY: "auto", // Enable scrolling
        }}
      >
        <Grid2
          container
          spacing={3}
          justifyContent="center"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {mainPages.map((page) => (
            <Grid2
              item
              xs={6}
              sm={4}
              md={3}
              key={page.path}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 3, // Add space between items
              }}
            >
              <Box
                sx={{
                  width: 100, // Fixed width for consistency
                  height: 100, // Fixed height for square-like dimension
                  textAlign: "center",
                  borderRadius: 4,
                  boxShadow: 3,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  transition: "transform 0.3s, box-shadow 0.3s", // Add animation transition
                  "&:hover": {
                    transform: "scale(1.05)", // Scale up on hover
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)", // Shadow on hover
                  },
                }}
              >
                <CardActionArea onClick={() => navigate(page.path)}>
                  <Card
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#e0f7fa",
                      borderRadius: 4,
                      overflow: "hidden", // Prevent icon overflow
                    }}
                  >
                    {/* Use an image icon instead of text */}
                    <Box
                      sx={{
                        width: "100%", // Icon size (covering most of the card)
                        height: "100%", // Icon size (covering most of the card)
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* Example image, replace with your actual image URL or icon */}
                      <img
                        src={page.image} // Assuming icon is a path to an image
                        alt={page.title}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain", // Make sure image fits inside the container
                        }}
                      />
                    </Box>
                  </Card>
                </CardActionArea>
              </Box>
              {/* Text below the card */}
              <Typography
                variant="body2"
                sx={{
                  fontSize: 12,
                  fontWeight: "bold",
                  color: "#333",
                  marginTop: 1,
                }}
              >
                {page.title}
              </Typography>
            </Grid2>
          ))}
        </Grid2>
      </Box>
    </Layout>
  );
};

export default Dashboard;
