import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import config from "../config";
import Layout from "../utils/Layout";
import { useToast } from "../utils/ToastContext";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [checkingSession, setCheckingSession] = useState(true);
  const [loading, setLoading] = useState(false); // Loading state for login
  const navigate = useNavigate();
  const triggerToast = useToast();

  // Check session on component mount
  useEffect(() => {
    const checkSession = async () => {
      const storedUsername = localStorage.getItem("username");
      const sessionId = localStorage.getItem("session_id");

      if (storedUsername && sessionId) {
        try {
          const response = await axios.post(
            `${config.apiBaseUrl}/check_session.php`,
            {
              username: storedUsername,
              session_id: sessionId,
            },
            { withCredentials: true }
          );

          if (response.data.success) {
            console.log("Session valid. Navigating to dashboard.");
            navigate("/dashboard");
          } else {
            console.log("Invalid session. Showing login form.");
          }
        } catch (err) {
          console.error("Error checking session:", err);
        }
      }
      setCheckingSession(false); // Mark session check as complete
    };

    checkSession();
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true); // Start loading spinner

    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/login.php`,
        { username, password },
        { withCredentials: true }
      );

      setLoading(false); // Stop loading spinner

      if (response.data.success) {
        // Store session ID and username in localStorage
        localStorage.setItem("session_id", response.data.session_id);
        localStorage.setItem("username", response.data.username);

        triggerToast("success", "Login successful! Redirecting...");
        navigate("/dashboard"); // Navigate to dashboard without reload
      } else {
        setError(response.data.message);
        triggerToast("error", response.data.message); // Show error toast
      }
    } catch (err) {
      setLoading(false); // Stop loading spinner
      setError("An error occurred. Please try again.");
      triggerToast("error", "An error occurred. Please try again.");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  if (checkingSession) {
    return (
      <Layout>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100vh"
        >
          <Typography variant="h6">Checking session, please wait...</Typography>
          <CircularProgress sx={{ marginLeft: 2 }} />
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Typography variant="h4" gutterBottom>
          Login
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        <form
          onSubmit={handleSubmit}
          style={{ width: "300px" }}
          onKeyDown={handleKeyPress} // Listen for Enter key press
        >
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            disabled={loading} // Disable input while loading
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading} // Disable input while loading
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            disabled={loading} // Disable button while loading
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Login"}
          </Button>
        </form>
      </Box>
    </Layout>
  );
};

export default LoginPage;
