import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Container,
  Divider,
  IconButton,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Layout from "../../utils/Layout";
import config from "../../config";
import { useToast } from "../../utils/ToastContext";
import LoadingPage from "../../utils/loading";

const EditQuote = () => {
  const currentDate = new Date().toISOString().split("T")[0];

  const [formData, setFormData] = useState({
    quote_name: "",
    quote_date: "",
    quoted_amount: "",
    description: "",
    submitted_to: "",
    username: localStorage.username,
    session_id: localStorage.session_id,

    quote_file: null,
    lpo_file: null,
    invoice_file: null,
    status: "",
    work_status: "",
    approved_amount: null,
    lpo_number: null,
    lpo_date: null,
    invoice_number: null,
    invoice_date: null,
    advance_amount: "",
    advance_received_date: "",
    date_of_completion: null,
    pay1: "",
    pay1_date: currentDate, // Set today's date as default
    pay2: "",
    pay2_date: currentDate, // Set today's date as default
    pay3: "",
    pay3_date: currentDate, // Set today's date as default
  });

  const triggerToast = useToast();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQuote = async () => {
      try {
        setLoading(true); // Set loading to true before starting the fetch
        const requestData = {
          id,
          username: localStorage.getItem("username"),
          session_id: localStorage.getItem("session_id"),
        };

        console.log("Fetching Quote with ID:", id);
        const response = await axios.post(
          `${config.apiBaseUrl}/get_by_id.php`,
          requestData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        console.log("Fetch Quote Request Sent:", requestData);
        console.log("Fetch Quote Response:", response.data);

        if (response.data.success) {
          setFormData(response.data.data);
        } else {
          triggerToast("error", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching quote:", error);
        triggerToast("error", "Failed to load quote details.");
      } finally {
        setLoading(false); // Set loading to false after fetch is complete
      }
    };

    fetchQuote();
  }, [id]); // Added `id` as dependency

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <LoadingPage />
      </Box>
    );
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const updatedFormData = {
        ...formData,
        id: id,
        username: localStorage.username,
        session_id: localStorage.session_id,
      };

      console.log("Submit Form Data:", updatedFormData);

      const response = await axios.post(
        `${config.apiBaseUrl}/update_quote.php`,
        updatedFormData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Submit Response:", response);

      if (response.data.success) {
        triggerToast("success", response.data.message);
        navigate("/quotes");
      } else {
        triggerToast("error", response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      triggerToast("error", "Something went wrong!");
    }
  };

  const handleBack = () => {
    navigate("/quotes");
  };

  const formatDecimal = (value) => {
    const number = Number(value);
    return number === 0 ? "" : value;
  };

  return (
    <Layout>
      <Container
        maxWidth="md"
        style={{
          overflow: "auto",
          maxHeight: "90vh",
          marginBottom: 10,
          scrollbarWidth: "thin",
          scrollbarColor: "lightgrey transparent",
          padding: "16px",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#ffffff",
        }}
      >
        <Box display="flex" alignItems="center" mb={3}>
          <IconButton onClick={handleBack}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h5" ml={1}>
            Edit Quote
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          {/* Basic Details Section */}
          <Box mb={4}>
            <Typography variant="h6" gutterBottom>
              Basic Details
            </Typography>
            <Divider />
            <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
              <TextField
                label="Reference No."
                variant="outlined"
                name="ref_no"
                value={formData.ref_no}
                onChange={handleChange}
                required
                fullWidth
                style={{ flex: "1 1 calc(50% - 16px)" }}
                InputProps={{
                  readOnly: true, // Makes the input field read-only
                }}
              />
              <TextField
                label="Quote Name"
                variant="outlined"
                name="quote_name"
                value={formData.quote_name}
                onChange={handleChange}
                required
                fullWidth
                style={{ flex: "1 1 calc(50% - 16px)" }}
                InputProps={{
                  readOnly: true, // Makes the input field read-only
                }}
              />
            </Box>

            <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
              <TextField
                label="Submitted To"
                variant="outlined"
                name="submitted_to"
                value={formData.submitted_to}
                onChange={handleChange}
                required
                fullWidth
                style={{ flex: "1 1 calc(33.3% - 16px)" }}
                InputProps={{
                  readOnly: true, // Makes the input field read-only
                }}
              />
              <TextField
                label="Quote Date"
                type="date"
                variant="outlined"
                name="quote_date"
                value={formData.quote_date}
                onChange={handleChange}
                required
                fullWidth
                style={{ flex: "1 1 calc(33.3% - 16px)" }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: true, // Makes the input field read-only
                }}
              />
              <TextField
                label="Quoted Amount"
                type="text"
                variant="outlined"
                name="quoted_amount"
                value={formData.quoted_amount}
                onChange={handleChange}
                required
                fullWidth
                style={{ flex: "1 1 calc(33.3% - 16px)" }}
                InputProps={{
                  readOnly: true, // Makes the input field read-only
                }}
              />
            </Box>
          </Box>

          {/* Description Section */}
          <Box mb={4}>
            <Typography variant="h6" gutterBottom>
              Description
            </Typography>
            <Divider />
            <Box mt={2}>
              <TextField
                label="Description"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                name="description"
                value={formData.description}
                onChange={handleChange}
                InputProps={{
                  readOnly: true, // Makes the input field read-only
                }}
              />
            </Box>
          </Box>

          {/* Financial Details Section */}
          <Box mb={4}>
            <Typography variant="h6" gutterBottom>
              Status
            </Typography>
            <Divider />
            <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
              <FormControl fullWidth style={{ flex: "1 1 calc(33.3% - 16px)" }}>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  required
                >
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="Approved">Approved</MenuItem>
                  <MenuItem value="Declined">Declined</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Approved Amount"
                type="number"
                variant="outlined"
                name="approved_amount"
                value={formData.approved_amount}
                onChange={handleChange}
                fullWidth
                style={{ flex: "1 1 calc(33.3% - 16px)" }}
              />
            </Box>
            <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
              <FormControl fullWidth style={{ flex: "1 1 calc(50% - 16px)" }}>
                <InputLabel>Work Status</InputLabel>
                <Select
                  label="Work Status"
                  name="work_status"
                  value={formData.work_status}
                  onChange={handleChange}
                  required
                >
                  <MenuItem value="Waiting LPO">Waiting LPO</MenuItem>
                  <MenuItem value="LPO Received">Lpo Received</MenuItem>
                  <MenuItem value="Hold">Hold</MenuItem>
                  <MenuItem value="Advance received">Advance received</MenuItem>
                  <MenuItem value="Work started">Work started</MenuItem>
                  <MenuItem value="Work completed">Work completed</MenuItem>
                  <MenuItem value="Closed">Closed</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Date of Completion"
                type="date"
                variant="outlined"
                name="date_of_completion"
                value={formData.date_of_completion}
                onChange={handleChange}
                fullWidth
                style={{ flex: "1 1 calc(50% - 16px)" }}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </Box>

          {/* LPO and Invoice Section */}
          <Box mb={4}>
            <Typography variant="h6" gutterBottom>
              LPO and Invoice
            </Typography>
            <Divider />
            <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
              <TextField
                label="LPO Number"
                variant="outlined"
                name="lpo_number"
                value={formData.lpo_number}
                onChange={handleChange}
                fullWidth
                style={{ flex: "1 1 calc(50% - 16px)" }}
              />
              <TextField
                label="LPO Date"
                type="date"
                variant="outlined"
                name="lpo_date"
                value={formData.lpo_date}
                onChange={handleChange}
                fullWidth
                style={{ flex: "1 1 calc(50% - 16px)" }}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Invoice Number"
                variant="outlined"
                name="invoice_number"
                value={formData.invoice_number}
                onChange={handleChange}
                fullWidth
                style={{ flex: "1 1 calc(50% - 16px)" }}
              />
              <TextField
                label="Invoice Date"
                type="date"
                variant="outlined"
                name="invoice_date"
                value={formData.invoice_date}
                onChange={handleChange}
                fullWidth
                style={{ flex: "1 1 calc(50% - 16px)" }}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </Box>

          {/* Payment Details Section */}
          <Box mb={4}>
            <Typography variant="h6" gutterBottom>
              Payment Details
            </Typography>
            <Divider />
            <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
              <TextField
                label="Advance Amount"
                type="text"
                variant="outlined"
                name="advance_amount"
                value={formData.advance_amount}
                onChange={handleChange}
                fullWidth
                style={{ flex: "1 1 calc(50% - 16px)" }}
              />
              <TextField
                label="Advance Date"
                type="date"
                variant="outlined"
                name="advance_date"
                value={formData.advance_date}
                onChange={handleChange}
                fullWidth
                style={{ flex: "1 1 calc(50% - 16px)" }}
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            {formData.advance_amount > 0 && (
              <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
                <TextField
                  label="Pay1"
                  type="text"
                  variant="outlined"
                  name="pay1"
                  value={formData.pay1}
                  onChange={handleChange}
                  fullWidth
                  style={{ flex: "1 1 calc(50% - 16px)" }}
                  inputProps={{
                    pattern: "[0-9]*([.][0-9]*)?", // Allows decimal numbers
                    min: 0, // Prevents negative values
                  }}
                />
                <TextField
                  label="Pay1 Date"
                  type="date"
                  variant="outlined"
                  name="pay1_date"
                  value={formData.pay1_date}
                  onChange={handleChange}
                  fullWidth
                  style={{ flex: "1 1 calc(50% - 16px)" }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            )}

            {formData.pay1 > 0 && (
              <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
                <TextField
                  label="Pay2"
                  type="text"
                  variant="outlined"
                  name="pay2"
                  value={formData.pay2}
                  onChange={handleChange}
                  fullWidth
                  style={{ flex: "1 1 calc(50% - 16px)" }}
                  inputProps={{
                    pattern: "[0-9]*([.][0-9]*)?", // Allows decimal numbers
                    min: 0, // Prevents negative values
                  }}
                />
                <TextField
                  label="Pay2 Date"
                  type="date"
                  variant="outlined"
                  name="pay2_date"
                  value={formData.pay2_date}
                  onChange={handleChange}
                  fullWidth
                  style={{ flex: "1 1 calc(50% - 16px)" }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            )}

            {formData.pay2 > 0 && (
              <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
                <TextField
                  label="Pay3"
                  type="text"
                  variant="outlined"
                  name="pay3"
                  value={formData.pay3}
                  onChange={handleChange}
                  fullWidth
                  style={{ flex: "1 1 calc(50% - 16px)" }}
                  inputProps={{
                    pattern: "[0-9]*([.][0-9]*)?", // Allows decimal numbers
                    min: 0, // Prevents negative values
                  }}
                />
                <TextField
                  label="Pay3 Date"
                  type="date"
                  variant="outlined"
                  name="pay3_date"
                  value={formData.pay3_date}
                  onChange={handleChange}
                  fullWidth
                  style={{ flex: "1 1 calc(50% - 16px)" }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            )}

            {formData.pay3 > 0 && (
              <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
                <TextField
                  label="Pay4"
                  type="text"
                  variant="outlined"
                  name="pay4"
                  value={formData.pay4}
                  onChange={handleChange}
                  fullWidth
                  style={{ flex: "1 1 calc(50% - 16px)" }}
                  inputProps={{
                    pattern: "[0-9]*([.][0-9]*)?", // Allows decimal numbers
                    min: 0, // Prevents negative values
                  }}
                />
                <TextField
                  label="Pay4 Date"
                  type="date"
                  variant="outlined"
                  name="pay4_date"
                  value={formData.pay4_date}
                  onChange={handleChange}
                  fullWidth
                  style={{ flex: "1 1 calc(50% - 16px)" }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            )}

            {formData.pay4 > 0 && (
              <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
                <TextField
                  label="Pay5"
                  type="text"
                  variant="outlined"
                  name="pay5"
                  value={formData.pay5}
                  onChange={handleChange}
                  fullWidth
                  style={{ flex: "1 1 calc(50% - 16px)" }}
                  inputProps={{
                    pattern: "[0-9]*([.][0-9]*)?", // Allows decimal numbers
                    min: 0, // Prevents negative values
                  }}
                />
                <TextField
                  label="Pay5 Date"
                  type="date"
                  variant="outlined"
                  name="pay5_date"
                  value={formData.pay5_date}
                  onChange={handleChange}
                  fullWidth
                  style={{ flex: "1 1 calc(50% - 16px)" }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            )}
          </Box>

          <Box mt={4} display="flex" justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ flex: 1, marginRight: "8px" }}
            >
              Save Changes
            </Button>
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              onClick={handleBack}
              style={{ flex: 1 }}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Container>
    </Layout>
  );
};

export default EditQuote;
